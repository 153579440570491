@import url("https://fonts.googleapis.com/css?family=Onest");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@500&display=swap");
/* Existing CSS for font face and icon styles */
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url("https://fonts.googleapis.com/css?family=Onest");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@500&display=swap");
@import '../../node_modules/tippy.js/dist/tippy.css';
@import '../../node_modules/tippy.js/themes/light.css';
@import './roboto.css';
@import './montserrat.css';
@font-face {
  font-family: "app-icons-font";
  src: url("./font/ai-icon.woff?u98a1e") format("woff"), url("./font/ai-icon.ttf?u98a1e") format("truetype"), url("./font/ai-icon.eot?u98a1e#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=ai-icon-],
[class*=" ai-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "app-icons-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utility classes for different icon sizes */
.ai-icon-1x {
  font-size: 1em; /* Default size */
}

.ai-icon-1-5x {
  font-size: 1.5em; /* Twice the default size */
}

.ai-icon-2x {
  font-size: 2em; /* Twice the default size */
}

.ai-icon-3x {
  font-size: 3em; /* Three times the default size */
}

.ai-icon-4x {
  font-size: 4em; /* Four times the default size */
}

/* Utility classes for rotating icons */
.ai-icon-rotate-90 {
  transform: rotate(90deg);
}

.ai-icon-rotate-180 {
  transform: rotate(180deg);
}

.ai-icon-rotate-270 {
  transform: rotate(270deg);
}

.ai-icon-flip-horizontal {
  transform: scaleX(-1);
}

.ai-icon-flip-vertical {
  transform: scaleY(-1);
}

.ai-icon-activity:before {
  content: "\e900";
}

.ai-icon-activity-heart:before {
  content: "\e901";
}

.ai-icon-airplay:before {
  content: "\e902";
}

.ai-icon-airpods:before {
  content: "\e903";
}

.ai-icon-alarm-clock:before {
  content: "\e904";
}

.ai-icon-alarm-clock-check:before {
  content: "\e905";
}

.ai-icon-alarm-clock-minus:before {
  content: "\e906";
}

.ai-icon-alarm-clock-off:before {
  content: "\e907";
}

.ai-icon-alarm-clock-plus:before {
  content: "\e908";
}

.ai-icon-alert-circle:before {
  content: "\e909";
}

.ai-icon-alert-hexagon:before {
  content: "\e90a";
}

.ai-icon-alert-octagon:before {
  content: "\e90b";
}

.ai-icon-alert-square:before {
  content: "\e90c";
}

.ai-icon-alert-triangle:before {
  content: "\e90d";
}

.ai-icon-align-bottom-01:before {
  content: "\e90e";
}

.ai-icon-align-bottom-02:before {
  content: "\e90f";
}

.ai-icon-align-center:before {
  content: "\e910";
}

.ai-icon-align-horizontal-centre-01:before {
  content: "\e911";
}

.ai-icon-align-horizontal-centre-02:before {
  content: "\e912";
}

.ai-icon-align-justify:before {
  content: "\e913";
}

.ai-icon-align-left:before {
  content: "\e914";
}

.ai-icon-align-left-01:before {
  content: "\e915";
}

.ai-icon-align-left-02:before {
  content: "\e916";
}

.ai-icon-align-right:before {
  content: "\e917";
}

.ai-icon-align-right-01:before {
  content: "\e918";
}

.ai-icon-align-right-02:before {
  content: "\e919";
}

.ai-icon-align-top-01:before {
  content: "\e91a";
}

.ai-icon-align-top-02:before {
  content: "\e91b";
}

.ai-icon-align-vertical-center-01:before {
  content: "\e91c";
}

.ai-icon-align-vertical-center-02:before {
  content: "\e91d";
}

.ai-icon-anchor:before {
  content: "\e91e";
}

.ai-icon-annotation:before {
  content: "\e91f";
}

.ai-icon-annotation-alert:before {
  content: "\e920";
}

.ai-icon-annotation-check:before {
  content: "\e921";
}

.ai-icon-annotation-dots:before {
  content: "\e922";
}

.ai-icon-annotation-heart:before {
  content: "\e923";
}

.ai-icon-annotation-info:before {
  content: "\e924";
}

.ai-icon-annotation-plus:before {
  content: "\e925";
}

.ai-icon-annotation-question:before {
  content: "\e926";
}

.ai-icon-annotation-x:before {
  content: "\e927";
}

.ai-icon-announcement-01:before {
  content: "\e928";
}

.ai-icon-announcement-02:before {
  content: "\e929";
}

.ai-icon-announcement-03:before {
  content: "\e92a";
}

.ai-icon-archive:before {
  content: "\e92b";
}

.ai-icon-arrow-block-down:before {
  content: "\e92c";
}

.ai-icon-arrow-block-left:before {
  content: "\e92d";
}

.ai-icon-arrow-block-right:before {
  content: "\e92e";
}

.ai-icon-arrow-block-up:before {
  content: "\e92f";
}

.ai-icon-arrow-circle-broken-down:before {
  content: "\e930";
}

.ai-icon-arrow-circle-broken-down-left:before {
  content: "\e931";
}

.ai-icon-arrow-circle-broken-down-right:before {
  content: "\e932";
}

.ai-icon-arrow-circle-broken-left:before {
  content: "\e933";
}

.ai-icon-arrow-circle-broken-right:before {
  content: "\e934";
}

.ai-icon-arrow-circle-broken-up:before {
  content: "\e935";
}

.ai-icon-arrow-circle-broken-up-left:before {
  content: "\e936";
}

.ai-icon-arrow-circle-broken-up-right:before {
  content: "\e937";
}

.ai-icon-arrow-circle-down:before {
  content: "\e938";
}

.ai-icon-arrow-circle-down-left:before {
  content: "\e939";
}

.ai-icon-arrow-circle-down-right:before {
  content: "\e93a";
}

.ai-icon-arrow-circle-left:before {
  content: "\e93b";
}

.ai-icon-arrow-circle-right:before {
  content: "\e93c";
}

.ai-icon-arrow-circle-up:before {
  content: "\e93d";
}

.ai-icon-arrow-circle-up-left:before {
  content: "\e93e";
}

.ai-icon-arrow-circle-up-right:before {
  content: "\e93f";
}

.ai-icon-arrow-down:before {
  content: "\e940";
}

.ai-icon-arrow-down-left:before {
  content: "\e941";
}

.ai-icon-arrow-down-right:before {
  content: "\e942";
}

.ai-icon-arrow-left:before {
  content: "\e943";
}

.ai-icon-arrow-narrow-down:before {
  content: "\e944";
}

.ai-icon-arrow-narrow-down-left:before {
  content: "\e945";
}

.ai-icon-arrow-narrow-down-right:before {
  content: "\e946";
}

.ai-icon-arrow-narrow-left:before {
  content: "\e947";
}

.ai-icon-arrow-narrow-right:before {
  content: "\e948";
}

.ai-icon-arrow-narrow-up:before {
  content: "\e949";
}

.ai-icon-arrow-narrow-up-left:before {
  content: "\e94a";
}

.ai-icon-arrow-narrow-up-right:before {
  content: "\e94b";
}

.ai-icon-arrow-right:before {
  content: "\e94c";
}

.ai-icon-arrows-down:before {
  content: "\e94d";
}

.ai-icon-arrows-left:before {
  content: "\e94e";
}

.ai-icon-arrow-square-down:before {
  content: "\e94f";
}

.ai-icon-arrow-square-down-left:before {
  content: "\e950";
}

.ai-icon-arrow-square-down-right:before {
  content: "\e951";
}

.ai-icon-arrow-square-left:before {
  content: "\e952";
}

.ai-icon-arrow-square-right:before {
  content: "\e953";
}

.ai-icon-arrow-square-up:before {
  content: "\e954";
}

.ai-icon-arrow-square-up-left:before {
  content: "\e955";
}

.ai-icon-arrow-square-up-right:before {
  content: "\e956";
}

.ai-icon-arrows-right:before {
  content: "\e957";
}

.ai-icon-arrows-triangle:before {
  content: "\e958";
}

.ai-icon-arrows-up:before {
  content: "\e959";
}

.ai-icon-arrow-up:before {
  content: "\e95a";
}

.ai-icon-arrow-up-left:before {
  content: "\e95b";
}

.ai-icon-arrow-up-right:before {
  content: "\e95c";
}

.ai-icon-asterisk-01:before {
  content: "\e95d";
}

.ai-icon-asterisk-02:before {
  content: "\e95e";
}

.ai-icon-atom-01:before {
  content: "\e95f";
}

.ai-icon-atom-02:before {
  content: "\e960";
}

.ai-icon-at-sign:before {
  content: "\e961";
}

.ai-icon-attachment-01:before {
  content: "\e962";
}

.ai-icon-attachment-02:before {
  content: "\e963";
}

.ai-icon-award-01:before {
  content: "\e964";
}

.ai-icon-award-02:before {
  content: "\e965";
}

.ai-icon-award-03:before {
  content: "\e966";
}

.ai-icon-award-04:before {
  content: "\e967";
}

.ai-icon-award-05:before {
  content: "\e968";
}

.ai-icon-backpack:before {
  content: "\e969";
}

.ai-icon-bank:before {
  content: "\e96a";
}

.ai-icon-bank-note-01:before {
  content: "\e96b";
}

.ai-icon-bank-note-02:before {
  content: "\e96c";
}

.ai-icon-bank-note-03:before {
  content: "\e96d";
}

.ai-icon-bar-chart-01:before {
  content: "\e96e";
}

.ai-icon-bar-chart-02:before {
  content: "\e96f";
}

.ai-icon-bar-chart-03:before {
  content: "\e970";
}

.ai-icon-bar-chart-04:before {
  content: "\e971";
}

.ai-icon-bar-chart-05:before {
  content: "\e972";
}

.ai-icon-bar-chart-06:before {
  content: "\e973";
}

.ai-icon-bar-chart-07:before {
  content: "\e974";
}

.ai-icon-bar-chart-08:before {
  content: "\e975";
}

.ai-icon-bar-chart-09:before {
  content: "\e976";
}

.ai-icon-bar-chart-10:before {
  content: "\e977";
}

.ai-icon-bar-chart-11:before {
  content: "\e978";
}

.ai-icon-bar-chart-12:before {
  content: "\e979";
}

.ai-icon-bar-chart-circle-01:before {
  content: "\e97a";
}

.ai-icon-bar-chart-circle-02:before {
  content: "\e97b";
}

.ai-icon-bar-chart-circle-03:before {
  content: "\e97c";
}

.ai-icon-bar-chart-square-01:before {
  content: "\e97d";
}

.ai-icon-bar-chart-square-02:before {
  content: "\e97e";
}

.ai-icon-bar-chart-square-03:before {
  content: "\e97f";
}

.ai-icon-bar-chart-square-down:before {
  content: "\e980";
}

.ai-icon-bar-chart-square-minus:before {
  content: "\e981";
}

.ai-icon-bar-chart-square-plus:before {
  content: "\e982";
}

.ai-icon-bar-chart-square-up:before {
  content: "\e983";
}

.ai-icon-bar-line-chart:before {
  content: "\e984";
}

.ai-icon-battery-charging-01:before {
  content: "\e985";
}

.ai-icon-battery-charging-02:before {
  content: "\e986";
}

.ai-icon-battery-empty:before {
  content: "\e987";
}

.ai-icon-battery-full:before {
  content: "\e988";
}

.ai-icon-battery-low:before {
  content: "\e989";
}

.ai-icon-battery-mid:before {
  content: "\e98a";
}

.ai-icon-beaker-01:before {
  content: "\e98b";
}

.ai-icon-beaker-02:before {
  content: "\e98c";
}

.ai-icon-bell-01:before {
  content: "\e98d";
}

.ai-icon-bell-02:before {
  content: "\e98e";
}

.ai-icon-bell-03:before {
  content: "\e98f";
}

.ai-icon-bell-04:before {
  content: "\e990";
}

.ai-icon-bell-minus:before {
  content: "\e991";
}

.ai-icon-bell-off-01:before {
  content: "\e992";
}

.ai-icon-bell-off-02:before {
  content: "\e993";
}

.ai-icon-bell-off-03:before {
  content: "\e994";
}

.ai-icon-bell-plus:before {
  content: "\e995";
}

.ai-icon-bell-ringing-01:before {
  content: "\e996";
}

.ai-icon-bell-ringing-02:before {
  content: "\e997";
}

.ai-icon-bell-ringing-03:before {
  content: "\e998";
}

.ai-icon-bell-ringing-04:before {
  content: "\e999";
}

.ai-icon-bezier-curve-01:before {
  content: "\e99a";
}

.ai-icon-bezier-curve-02:before {
  content: "\e99b";
}

.ai-icon-bezier-curve-03:before {
  content: "\e99c";
}

.ai-icon-bluetooth-connect:before {
  content: "\e99d";
}

.ai-icon-bluetooth-off:before {
  content: "\e99e";
}

.ai-icon-bluetooth-on:before {
  content: "\e99f";
}

.ai-icon-bluetooth-signal:before {
  content: "\e9a0";
}

.ai-icon-bold-01:before {
  content: "\e9a1";
}

.ai-icon-bold-02:before {
  content: "\e9a2";
}

.ai-icon-bold-square:before {
  content: "\e9a3";
}

.ai-icon-book-closed:before {
  content: "\e9a4";
}

.ai-icon-book-closed-1:before {
  content: "\e9a5";
}

.ai-icon-bookmark:before {
  content: "\e9a6";
}

.ai-icon-bookmark-add:before {
  content: "\e9a7";
}

.ai-icon-bookmark-check:before {
  content: "\e9a8";
}

.ai-icon-bookmark-minus:before {
  content: "\e9a9";
}

.ai-icon-bookmark-x:before {
  content: "\e9aa";
}

.ai-icon-book-open-01:before {
  content: "\e9ab";
}

.ai-icon-book-open-02:before {
  content: "\e9ac";
}

.ai-icon-book-open-writed:before {
  content: "\e9ad";
}

.ai-icon-box:before {
  content: "\e9ae";
}

.ai-icon-brackets:before {
  content: "\e9af";
}

.ai-icon-brackets-check:before {
  content: "\e9b0";
}

.ai-icon-brackets-ellipses:before {
  content: "\e9b1";
}

.ai-icon-brackets-minus:before {
  content: "\e9b2";
}

.ai-icon-brackets-plus:before {
  content: "\e9b3";
}

.ai-icon-brackets-slash:before {
  content: "\e9b4";
}

.ai-icon-brackets-x:before {
  content: "\e9b5";
}

.ai-icon-briefcase-01:before {
  content: "\e9b6";
}

.ai-icon-briefcase-02:before {
  content: "\e9b7";
}

.ai-icon-browser:before {
  content: "\e9b8";
}

.ai-icon-brush-01:before {
  content: "\e9b9";
}

.ai-icon-brush-02:before {
  content: "\e9ba";
}

.ai-icon-brush-03:before {
  content: "\e9bb";
}

.ai-icon-building-01:before {
  content: "\e9bc";
}

.ai-icon-building-02:before {
  content: "\e9bd";
}

.ai-icon-building-03:before {
  content: "\e9be";
}

.ai-icon-building-04:before {
  content: "\e9bf";
}

.ai-icon-building-05:before {
  content: "\e9c0";
}

.ai-icon-building-06:before {
  content: "\e9c1";
}

.ai-icon-building-07:before {
  content: "\e9c2";
}

.ai-icon-building-08:before {
  content: "\e9c3";
}

.ai-icon-bus:before {
  content: "\e9c4";
}

.ai-icon-calculator:before {
  content: "\e9c5";
}

.ai-icon-calendar:before {
  content: "\e9c6";
}

.ai-icon-calendar-check-01:before {
  content: "\e9c7";
}

.ai-icon-calendar-check-02:before {
  content: "\e9c8";
}

.ai-icon-calendar-date:before {
  content: "\e9c9";
}

.ai-icon-calendar-heart-01:before {
  content: "\e9ca";
}

.ai-icon-calendar-heart-02:before {
  content: "\e9cb";
}

.ai-icon-calendar-minus-01:before {
  content: "\e9cc";
}

.ai-icon-calendar-minus-02:before {
  content: "\e9cd";
}

.ai-icon-calendar-plus-01:before {
  content: "\e9ce";
}

.ai-icon-calendar-plus-02:before {
  content: "\e9cf";
}

.ai-icon-camera-01:before {
  content: "\e9d0";
}

.ai-icon-camera-02:before {
  content: "\e9d1";
}

.ai-icon-camera-03:before {
  content: "\e9d2";
}

.ai-icon-camera-lens:before {
  content: "\e9d3";
}

.ai-icon-camera-off:before {
  content: "\e9d4";
}

.ai-icon-camera-plus:before {
  content: "\e9d5";
}

.ai-icon-car-01:before {
  content: "\e9d6";
}

.ai-icon-car-02:before {
  content: "\e9d7";
}

.ai-icon-certificate-01:before {
  content: "\e9d8";
}

.ai-icon-certificate-02:before {
  content: "\e9d9";
}

.ai-icon-chart-breakout-circle:before {
  content: "\e9da";
}

.ai-icon-chart-breakout-square:before {
  content: "\e9db";
}

.ai-icon-check:before {
  content: "\e9dc";
}

.ai-icon-check-circle:before {
  content: "\e9dd";
}

.ai-icon-check-circle-broken:before {
  content: "\e9de";
}

.ai-icon-check-done-01:before {
  content: "\e9df";
}

.ai-icon-check-done-02:before {
  content: "\e9e0";
}

.ai-icon-check-done-03:before {
  content: "\e9e1";
}

.ai-icon-check-heart:before {
  content: "\e9e2";
}

.ai-icon-check-square:before {
  content: "\e9e3";
}

.ai-icon-check-square-broken:before {
  content: "\e9e4";
}

.ai-icon-check-verified-01:before {
  content: "\e9e5";
}

.ai-icon-check-verified-02:before {
  content: "\e9e6";
}

.ai-icon-check-verified-03:before {
  content: "\e9e7";
}

.ai-icon-chevron-down:before {
  content: "\e9e8";
}

.ai-icon-chevron-down-double:before {
  content: "\e9e9";
}

.ai-icon-chevron-left:before {
  content: "\e9ea";
}

.ai-icon-chevron-left-double:before {
  content: "\e9eb";
}

.ai-icon-chevron-right:before {
  content: "\e9ec";
}

.ai-icon-chevron-right-double:before {
  content: "\e9ed";
}

.ai-icon-chevron-selector-horizontal:before {
  content: "\e9ee";
}

.ai-icon-chevron-selector-vertical:before {
  content: "\e9ef";
}

.ai-icon-chevron-up:before {
  content: "\e9f0";
}

.ai-icon-chevron-up-double:before {
  content: "\e9f1";
}

.ai-icon-chrome-cast:before {
  content: "\e9f2";
}

.ai-icon-circle:before {
  content: "\e9f3";
}

.ai-icon-circle-cut:before {
  content: "\e9f4";
}

.ai-icon-clapperboard:before {
  content: "\e9f5";
}

.ai-icon-clipboard:before {
  content: "\e9f6";
}

.ai-icon-clipboard-attachment:before {
  content: "\e9f7";
}

.ai-icon-clipboard-check:before {
  content: "\e9f8";
}

.ai-icon-clipboard-download:before {
  content: "\e9f9";
}

.ai-icon-clipboard-minus:before {
  content: "\e9fa";
}

.ai-icon-clipboard-plus:before {
  content: "\e9fb";
}

.ai-icon-clipboard-x:before {
  content: "\e9fc";
}

.ai-icon-clock:before {
  content: "\e9fd";
}

.ai-icon-clock-check:before {
  content: "\e9fe";
}

.ai-icon-clock-fast-forward:before {
  content: "\e9ff";
}

.ai-icon-clock-plus:before {
  content: "\ea00";
}

.ai-icon-clock-refresh:before {
  content: "\ea01";
}

.ai-icon-clock-rewind:before {
  content: "\ea02";
}

.ai-icon-clock-snooze:before {
  content: "\ea03";
}

.ai-icon-clock-stopwatch:before {
  content: "\ea04";
}

.ai-icon-cloud-01:before {
  content: "\ea05";
}

.ai-icon-cloud-02:before {
  content: "\ea06";
}

.ai-icon-cloud-03:before {
  content: "\ea07";
}

.ai-icon-cloud-blank-01:before {
  content: "\ea08";
}

.ai-icon-cloud-blank-02:before {
  content: "\ea09";
}

.ai-icon-cloud-lightning:before {
  content: "\ea0a";
}

.ai-icon-cloud-moon:before {
  content: "\ea0b";
}

.ai-icon-cloud-off:before {
  content: "\ea0c";
}

.ai-icon-cloud-raining-01:before {
  content: "\ea0d";
}

.ai-icon-cloud-raining-02:before {
  content: "\ea0e";
}

.ai-icon-cloud-raining-03:before {
  content: "\ea0f";
}

.ai-icon-cloud-raining-04:before {
  content: "\ea10";
}

.ai-icon-cloud-raining-05:before {
  content: "\ea11";
}

.ai-icon-cloud-raining-06:before {
  content: "\ea12";
}

.ai-icon-cloud-snowing-01:before {
  content: "\ea13";
}

.ai-icon-cloud-snowing-02:before {
  content: "\ea14";
}

.ai-icon-cloud-sun-01:before {
  content: "\ea15";
}

.ai-icon-cloud-sun-02:before {
  content: "\ea16";
}

.ai-icon-cloud-sun-03:before {
  content: "\ea17";
}

.ai-icon-code-01:before {
  content: "\ea18";
}

.ai-icon-code-02:before {
  content: "\ea19";
}

.ai-icon-code-browser:before {
  content: "\ea1a";
}

.ai-icon-code-circle-01:before {
  content: "\ea1b";
}

.ai-icon-code-circle-02:before {
  content: "\ea1c";
}

.ai-icon-code-circle-03:before {
  content: "\ea1d";
}

.ai-icon-codepen:before {
  content: "\ea1e";
}

.ai-icon-code-snippet-01:before {
  content: "\ea1f";
}

.ai-icon-code-snippet-02:before {
  content: "\ea20";
}

.ai-icon-code-square-01:before {
  content: "\ea21";
}

.ai-icon-code-square-02:before {
  content: "\ea22";
}

.ai-icon-coins-01:before {
  content: "\ea23";
}

.ai-icon-coins-02:before {
  content: "\ea24";
}

.ai-icon-coins-03:before {
  content: "\ea25";
}

.ai-icon-coins-04:before {
  content: "\ea26";
}

.ai-icon-coins-hand:before {
  content: "\ea27";
}

.ai-icon-coins-stacked-01:before {
  content: "\ea28";
}

.ai-icon-coins-stacked-02:before {
  content: "\ea29";
}

.ai-icon-coins-stacked-03:before {
  content: "\ea2a";
}

.ai-icon-coins-stacked-04:before {
  content: "\ea2b";
}

.ai-icon-coins-swap-01:before {
  content: "\ea2c";
}

.ai-icon-coins-swap-02:before {
  content: "\ea2d";
}

.ai-icon-colors:before {
  content: "\ea2e";
}

.ai-icon-colors-1:before {
  content: "\ea2f";
}

.ai-icon-columns-01:before {
  content: "\ea30";
}

.ai-icon-columns-02:before {
  content: "\ea31";
}

.ai-icon-columns-03:before {
  content: "\ea32";
}

.ai-icon-command:before {
  content: "\ea33";
}

.ai-icon-compass:before {
  content: "\ea34";
}

.ai-icon-compass-01:before {
  content: "\ea35";
}

.ai-icon-compass-02:before {
  content: "\ea36";
}

.ai-icon-compass-03:before {
  content: "\ea37";
}

.ai-icon-container:before {
  content: "\ea38";
}

.ai-icon-contrast-01:before {
  content: "\ea39";
}

.ai-icon-contrast-02:before {
  content: "\ea3a";
}

.ai-icon-contrast-03:before {
  content: "\ea3b";
}

.ai-icon-copy-01:before {
  content: "\ea3c";
}

.ai-icon-copy-02:before {
  content: "\ea3d";
}

.ai-icon-copy-03:before {
  content: "\ea3e";
}

.ai-icon-copy-04:before {
  content: "\ea3f";
}

.ai-icon-copy-05:before {
  content: "\ea40";
}

.ai-icon-copy-06:before {
  content: "\ea41";
}

.ai-icon-copy-07:before {
  content: "\ea42";
}

.ai-icon-corner-down-left:before {
  content: "\ea43";
}

.ai-icon-corner-down-right:before {
  content: "\ea44";
}

.ai-icon-corner-left-down:before {
  content: "\ea45";
}

.ai-icon-corner-left-up:before {
  content: "\ea46";
}

.ai-icon-corner-right-down:before {
  content: "\ea47";
}

.ai-icon-corner-right-up:before {
  content: "\ea48";
}

.ai-icon-corner-up-left:before {
  content: "\ea49";
}

.ai-icon-corner-up-right:before {
  content: "\ea4a";
}

.ai-icon-cpu-chip-01:before {
  content: "\ea4b";
}

.ai-icon-cpu-chip-02:before {
  content: "\ea4c";
}

.ai-icon-credit-card-01:before {
  content: "\ea4d";
}

.ai-icon-credit-card-02:before {
  content: "\ea4e";
}

.ai-icon-credit-card-check:before {
  content: "\ea4f";
}

.ai-icon-credit-card-down:before {
  content: "\ea50";
}

.ai-icon-credit-card-download:before {
  content: "\ea51";
}

.ai-icon-credit-card-edit:before {
  content: "\ea52";
}

.ai-icon-credit-card-lock:before {
  content: "\ea53";
}

.ai-icon-credit-card-minus:before {
  content: "\ea54";
}

.ai-icon-credit-card-plus:before {
  content: "\ea55";
}

.ai-icon-credit-card-refresh:before {
  content: "\ea56";
}

.ai-icon-credit-card-search:before {
  content: "\ea57";
}

.ai-icon-credit-card-shield:before {
  content: "\ea58";
}

.ai-icon-credit-card-up:before {
  content: "\ea59";
}

.ai-icon-credit-card-upload:before {
  content: "\ea5a";
}

.ai-icon-credit-card-x:before {
  content: "\ea5b";
}

.ai-icon-crop-01:before {
  content: "\ea5c";
}

.ai-icon-crop-02:before {
  content: "\ea5d";
}

.ai-icon-cryptocurrency-01:before {
  content: "\ea5e";
}

.ai-icon-cryptocurrency-02:before {
  content: "\ea5f";
}

.ai-icon-cryptocurrency-03:before {
  content: "\ea60";
}

.ai-icon-cryptocurrency-04:before {
  content: "\ea61";
}

.ai-icon-cube-01:before {
  content: "\ea62";
}

.ai-icon-cube-02:before {
  content: "\ea63";
}

.ai-icon-cube-03:before {
  content: "\ea64";
}

.ai-icon-cube-04:before {
  content: "\ea65";
}

.ai-icon-cube-outline:before {
  content: "\ea66";
}

.ai-icon-currency-bitcoin:before {
  content: "\ea67";
}

.ai-icon-currency-bitcoin-circle:before {
  content: "\ea68";
}

.ai-icon-currency-dollar:before {
  content: "\ea69";
}

.ai-icon-currency-dollar-circle:before {
  content: "\ea6a";
}

.ai-icon-currency-ethereum:before {
  content: "\ea6b";
}

.ai-icon-currency-ethereum-circle:before {
  content: "\ea6c";
}

.ai-icon-currency-euro:before {
  content: "\ea6d";
}

.ai-icon-currency-euro-circle:before {
  content: "\ea6e";
}

.ai-icon-currency-pound:before {
  content: "\ea6f";
}

.ai-icon-currency-pound-circle:before {
  content: "\ea70";
}

.ai-icon-currency-ruble:before {
  content: "\ea71";
}

.ai-icon-currency-ruble-circle:before {
  content: "\ea72";
}

.ai-icon-currency-rupee:before {
  content: "\ea73";
}

.ai-icon-currency-rupee-circle:before {
  content: "\ea74";
}

.ai-icon-currency-yen:before {
  content: "\ea75";
}

.ai-icon-currency-yen-circle:before {
  content: "\ea76";
}

.ai-icon-cursor-01:before {
  content: "\ea77";
}

.ai-icon-cursor-02:before {
  content: "\ea78";
}

.ai-icon-cursor-03:before {
  content: "\ea79";
}

.ai-icon-cursor-04:before {
  content: "\ea7a";
}

.ai-icon-cursor-box:before {
  content: "\ea7b";
}

.ai-icon-cursor-click-01:before {
  content: "\ea7c";
}

.ai-icon-cursor-click-02:before {
  content: "\ea7d";
}

.ai-icon-data:before {
  content: "\ea7e";
}

.ai-icon-database-01:before {
  content: "\ea7f";
}

.ai-icon-database-02:before {
  content: "\ea80";
}

.ai-icon-database-03:before {
  content: "\ea81";
}

.ai-icon-dataflow-01:before {
  content: "\ea82";
}

.ai-icon-dataflow-02:before {
  content: "\ea83";
}

.ai-icon-dataflow-03:before {
  content: "\ea84";
}

.ai-icon-dataflow-04:before {
  content: "\ea85";
}

.ai-icon-delete:before {
  content: "\ea86";
}

.ai-icon-diamond-01:before {
  content: "\ea87";
}

.ai-icon-diamond-02:before {
  content: "\ea88";
}

.ai-icon-dice-1:before {
  content: "\ea89";
}

.ai-icon-dice-2:before {
  content: "\ea8a";
}

.ai-icon-dice-3:before {
  content: "\ea8b";
}

.ai-icon-dice-4:before {
  content: "\ea8c";
}

.ai-icon-dice-5:before {
  content: "\ea8d";
}

.ai-icon-dice-6:before {
  content: "\ea8e";
}

.ai-icon-disc-01:before {
  content: "\ea8f";
}

.ai-icon-disc-02:before {
  content: "\ea90";
}

.ai-icon-distribute-spacing-horizontal:before {
  content: "\ea91";
}

.ai-icon-distribute-spacing-vertical:before {
  content: "\ea92";
}

.ai-icon-divide-01:before {
  content: "\ea93";
}

.ai-icon-divide-02:before {
  content: "\ea94";
}

.ai-icon-divide-03:before {
  content: "\ea95";
}

.ai-icon-divider:before {
  content: "\ea96";
}

.ai-icon-dotpoints-01:before {
  content: "\ea97";
}

.ai-icon-dotpoints-02:before {
  content: "\ea98";
}

.ai-icon-dots-grid:before {
  content: "\ea99";
}

.ai-icon-dots-horizontal:before {
  content: "\ea9a";
}

.ai-icon-dots-vertical:before {
  content: "\ea9b";
}

.ai-icon-download-01:before {
  content: "\ea9c";
}

.ai-icon-download-02:before {
  content: "\ea9d";
}

.ai-icon-download-03:before {
  content: "\ea9e";
}

.ai-icon-download-04:before {
  content: "\ea9f";
}

.ai-icon-download-cloud-01:before {
  content: "\eaa0";
}

.ai-icon-download-cloud-02:before {
  content: "\eaa1";
}

.ai-icon-drop:before {
  content: "\eaa2";
}

.ai-icon-droplets-01:before {
  content: "\eaa3";
}

.ai-icon-droplets-02:before {
  content: "\eaa4";
}

.ai-icon-droplets-03:before {
  content: "\eaa5";
}

.ai-icon-dropper:before {
  content: "\eaa6";
}

.ai-icon-edit-01:before {
  content: "\eaa7";
}

.ai-icon-edit-02:before {
  content: "\eaa8";
}

.ai-icon-edit-03:before {
  content: "\eaa9";
}

.ai-icon-edit-04:before {
  content: "\eaaa";
}

.ai-icon-edit-05:before {
  content: "\eaab";
}

.ai-icon-equal:before {
  content: "\eaac";
}

.ai-icon-equal-not:before {
  content: "\eaad";
}

.ai-icon-eraser:before {
  content: "\eaae";
}

.ai-icon-expand-01:before {
  content: "\eaaf";
}

.ai-icon-expand-02:before {
  content: "\eab0";
}

.ai-icon-expand-03:before {
  content: "\eab1";
}

.ai-icon-expand-04:before {
  content: "\eab2";
}

.ai-icon-expand-05:before {
  content: "\eab3";
}

.ai-icon-expand-06:before {
  content: "\eab4";
}

.ai-icon-eye:before {
  content: "\eab5";
}

.ai-icon-eye-off:before {
  content: "\eab6";
}

.ai-icon-face-content:before {
  content: "\eab7";
}

.ai-icon-face-frown:before {
  content: "\eab8";
}

.ai-icon-face-happy:before {
  content: "\eab9";
}

.ai-icon-face-id:before {
  content: "\eaba";
}

.ai-icon-face-id-square:before {
  content: "\eabb";
}

.ai-icon-face-neutral:before {
  content: "\eabc";
}

.ai-icon-face-sad:before {
  content: "\eabd";
}

.ai-icon-face-smile:before {
  content: "\eabe";
}

.ai-icon-face-wink:before {
  content: "\eabf";
}

.ai-icon-fast-backward:before {
  content: "\eac0";
}

.ai-icon-fast-forward:before {
  content: "\eac1";
}

.ai-icon-feather:before {
  content: "\eac2";
}

.ai-icon-figma:before {
  content: "\eac3";
}

.ai-icon-file-01:before {
  content: "\eac4";
}

.ai-icon-file-02:before {
  content: "\eac5";
}

.ai-icon-file-03:before {
  content: "\eac6";
}

.ai-icon-file-04:before {
  content: "\eac7";
}

.ai-icon-file-05:before {
  content: "\eac8";
}

.ai-icon-file-06:before {
  content: "\eac9";
}

.ai-icon-file-07:before {
  content: "\eaca";
}

.ai-icon-file-attachment-01:before {
  content: "\eacb";
}

.ai-icon-file-attachment-02:before {
  content: "\eacc";
}

.ai-icon-file-attachment-03:before {
  content: "\eacd";
}

.ai-icon-file-attachment-04:before {
  content: "\eace";
}

.ai-icon-file-attachment-05:before {
  content: "\eacf";
}

.ai-icon-file-check-01:before {
  content: "\ead0";
}

.ai-icon-file-check-02:before {
  content: "\ead1";
}

.ai-icon-file-check-03:before {
  content: "\ead2";
}

.ai-icon-file-code-01:before {
  content: "\ead3";
}

.ai-icon-file-code-02:before {
  content: "\ead4";
}

.ai-icon-file-download-01:before {
  content: "\ead5";
}

.ai-icon-file-download-02:before {
  content: "\ead6";
}

.ai-icon-file-download-03:before {
  content: "\ead7";
}

.ai-icon-file-heart-01:before {
  content: "\ead8";
}

.ai-icon-file-heart-02:before {
  content: "\ead9";
}

.ai-icon-file-heart-03:before {
  content: "\eada";
}

.ai-icon-file-lock-01:before {
  content: "\eadb";
}

.ai-icon-file-lock-02:before {
  content: "\eadc";
}

.ai-icon-file-lock-03:before {
  content: "\eadd";
}

.ai-icon-file-minus-01:before {
  content: "\eade";
}

.ai-icon-file-minus-02:before {
  content: "\eadf";
}

.ai-icon-file-minus-03:before {
  content: "\eae0";
}

.ai-icon-file-plus-01:before {
  content: "\eae1";
}

.ai-icon-file-plus-02:before {
  content: "\eae2";
}

.ai-icon-file-plus-03:before {
  content: "\eae3";
}

.ai-icon-file-question-01:before {
  content: "\eae4";
}

.ai-icon-file-question-02:before {
  content: "\eae5";
}

.ai-icon-file-question-03:before {
  content: "\eae6";
}

.ai-icon-file-search-01:before {
  content: "\eae7";
}

.ai-icon-file-search-02:before {
  content: "\eae8";
}

.ai-icon-file-search-03:before {
  content: "\eae9";
}

.ai-icon-file-shield-01:before {
  content: "\eaea";
}

.ai-icon-file-shield-02:before {
  content: "\eaeb";
}

.ai-icon-file-shield-03:before {
  content: "\eaec";
}

.ai-icon-file-x-01:before {
  content: "\eaed";
}

.ai-icon-file-x-02:before {
  content: "\eaee";
}

.ai-icon-file-x-03:before {
  content: "\eaef";
}

.ai-icon-film-01:before {
  content: "\eaf0";
}

.ai-icon-film-02:before {
  content: "\eaf1";
}

.ai-icon-film-03:before {
  content: "\eaf2";
}

.ai-icon-filter-funnel-01:before {
  content: "\eaf3";
}

.ai-icon-filter-funnel-02:before {
  content: "\eaf4";
}

.ai-icon-filter-lines:before {
  content: "\eaf5";
}

.ai-icon-fingerprint-01:before {
  content: "\eaf6";
}

.ai-icon-fingerprint-02:before {
  content: "\eaf7";
}

.ai-icon-fingerprint-03:before {
  content: "\eaf8";
}

.ai-icon-fingerprint-04:before {
  content: "\eaf9";
}

.ai-icon-flag-01:before {
  content: "\eafa";
}

.ai-icon-flag-02:before {
  content: "\eafb";
}

.ai-icon-flag-03:before {
  content: "\eafc";
}

.ai-icon-flag-04:before {
  content: "\eafd";
}

.ai-icon-flag-05:before {
  content: "\eafe";
}

.ai-icon-flag-06:before {
  content: "\eaff";
}

.ai-icon-flag-en:before {
  content: "\eb00";
  color: #012169;
}

.ai-icon-flash:before {
  content: "\eb01";
}

.ai-icon-flash-off:before {
  content: "\eb02";
}

.ai-icon-flex-align-bottom:before {
  content: "\eb03";
}

.ai-icon-flex-align-left:before {
  content: "\eb04";
}

.ai-icon-flex-align-right:before {
  content: "\eb05";
}

.ai-icon-flex-align-top:before {
  content: "\eb06";
}

.ai-icon-flip-backward:before {
  content: "\eb07";
}

.ai-icon-flip-forward:before {
  content: "\eb08";
}

.ai-icon-folder:before {
  content: "\eb09";
}

.ai-icon-folder-check:before {
  content: "\eb0a";
}

.ai-icon-folder-closed:before {
  content: "\eb0b";
}

.ai-icon-folder-code:before {
  content: "\eb0c";
}

.ai-icon-folder-download:before {
  content: "\eb0d";
}

.ai-icon-folder-lock:before {
  content: "\eb0e";
}

.ai-icon-folder-minus:before {
  content: "\eb0f";
}

.ai-icon-folder-plus:before {
  content: "\eb10";
}

.ai-icon-folder-question:before {
  content: "\eb11";
}

.ai-icon-folder-search:before {
  content: "\eb12";
}

.ai-icon-folder-shield:before {
  content: "\eb13";
}

.ai-icon-folder-x:before {
  content: "\eb14";
}

.ai-icon-framer:before {
  content: "\eb15";
}

.ai-icon-gaming-pad-01:before {
  content: "\eb16";
}

.ai-icon-gaming-pad-02:before {
  content: "\eb17";
}

.ai-icon-gift-01:before {
  content: "\eb18";
}

.ai-icon-gift-02:before {
  content: "\eb19";
}

.ai-icon-git-branch-01:before {
  content: "\eb1a";
}

.ai-icon-git-branch-02:before {
  content: "\eb1b";
}

.ai-icon-git-commit:before {
  content: "\eb1c";
}

.ai-icon-git-merge:before {
  content: "\eb1d";
}

.ai-icon-git-pull-request:before {
  content: "\eb1e";
}

.ai-icon-glasses-01:before {
  content: "\eb1f";
}

.ai-icon-glasses-02:before {
  content: "\eb20";
}

.ai-icon-globe-01:before {
  content: "\eb21";
}

.ai-icon-globe-02:before {
  content: "\eb22";
}

.ai-icon-globe-03:before {
  content: "\eb23";
}

.ai-icon-globe-04:before {
  content: "\eb24";
}

.ai-icon-globe-05:before {
  content: "\eb25";
}

.ai-icon-globe-06:before {
  content: "\eb26";
}

.ai-icon-globe-slated-01:before {
  content: "\eb27";
}

.ai-icon-globe-slated-02:before {
  content: "\eb28";
}

.ai-icon-google-chrome:before {
  content: "\eb29";
}

.ai-icon-graduation-hat-01:before {
  content: "\eb2a";
}

.ai-icon-graduation-hat-02:before {
  content: "\eb2b";
}

.ai-icon-grid-01:before {
  content: "\eb2c";
}

.ai-icon-grid-02:before {
  content: "\eb2d";
}

.ai-icon-grid-03:before {
  content: "\eb2e";
}

.ai-icon-grid-dots-blank:before {
  content: "\eb2f";
}

.ai-icon-grid-dots-bottom:before {
  content: "\eb30";
}

.ai-icon-grid-dots-horizontal-center:before {
  content: "\eb31";
}

.ai-icon-grid-dots-left:before {
  content: "\eb32";
}

.ai-icon-grid-dots-outer:before {
  content: "\eb33";
}

.ai-icon-grid-dots-right:before {
  content: "\eb34";
}

.ai-icon-grid-dots-top:before {
  content: "\eb35";
}

.ai-icon-grid-dots-vertical-center:before {
  content: "\eb36";
}

.ai-icon-hand:before {
  content: "\eb37";
}

.ai-icon-hard-drive:before {
  content: "\eb38";
}

.ai-icon-hash-01:before {
  content: "\eb39";
}

.ai-icon-hash-02:before {
  content: "\eb3a";
}

.ai-icon-heading-01:before {
  content: "\eb3b";
}

.ai-icon-heading-02:before {
  content: "\eb3c";
}

.ai-icon-heading-square:before {
  content: "\eb3d";
}

.ai-icon-headphones-01:before {
  content: "\eb3e";
}

.ai-icon-headphones-02:before {
  content: "\eb3f";
}

.ai-icon-heart:before {
  content: "\eb40";
}

.ai-icon-heart-circle:before {
  content: "\eb41";
}

.ai-icon-heart-hand:before {
  content: "\eb42";
}

.ai-icon-heart-hexagon:before {
  content: "\eb43";
}

.ai-icon-heart-octagon:before {
  content: "\eb44";
}

.ai-icon-heart-rounded:before {
  content: "\eb45";
}

.ai-icon-hearts:before {
  content: "\eb46";
}

.ai-icon-heart-square:before {
  content: "\eb47";
}

.ai-icon-help-circle:before {
  content: "\eb48";
}

.ai-icon-help-hexagon:before {
  content: "\eb49";
}

.ai-icon-help-octagon:before {
  content: "\eb4a";
}

.ai-icon-help-square:before {
  content: "\eb4b";
}

.ai-icon-hexagon-01:before {
  content: "\eb4c";
}

.ai-icon-hexagon-02:before {
  content: "\eb4d";
}

.ai-icon-home-01:before {
  content: "\eb4e";
}

.ai-icon-home-02:before {
  content: "\eb4f";
}

.ai-icon-home-03:before {
  content: "\eb50";
}

.ai-icon-home-04:before {
  content: "\eb51";
}

.ai-icon-home-05:before {
  content: "\eb52";
}

.ai-icon-home-line:before {
  content: "\eb53";
}

.ai-icon-home-smile:before {
  content: "\eb54";
}

.ai-icon-horizontal-bar-chart-01:before {
  content: "\eb55";
}

.ai-icon-horizontal-bar-chart-02:before {
  content: "\eb56";
}

.ai-icon-horizontal-bar-chart-03:before {
  content: "\eb57";
}

.ai-icon-hourglass-01:before {
  content: "\eb58";
}

.ai-icon-hourglass-02:before {
  content: "\eb59";
}

.ai-icon-hourglass-03:before {
  content: "\eb5a";
}

.ai-icon-hurricane-01:before {
  content: "\eb5b";
}

.ai-icon-hurricane-02:before {
  content: "\eb5c";
}

.ai-icon-hurricane-03:before {
  content: "\eb5d";
}

.ai-icon-image-01:before {
  content: "\eb5e";
}

.ai-icon-image-02:before {
  content: "\eb5f";
}

.ai-icon-image-03:before {
  content: "\eb60";
}

.ai-icon-image-04:before {
  content: "\eb61";
}

.ai-icon-image-05:before {
  content: "\eb62";
}

.ai-icon-image-check:before {
  content: "\eb63";
}

.ai-icon-image-down:before {
  content: "\eb64";
}

.ai-icon-image-indent-left:before {
  content: "\eb65";
}

.ai-icon-image-indent-right:before {
  content: "\eb66";
}

.ai-icon-image-left:before {
  content: "\eb67";
}

.ai-icon-image-plus:before {
  content: "\eb68";
}

.ai-icon-image-right:before {
  content: "\eb69";
}

.ai-icon-image-up:before {
  content: "\eb6a";
}

.ai-icon-image-user:before {
  content: "\eb6b";
}

.ai-icon-image-user-check:before {
  content: "\eb6c";
}

.ai-icon-image-user-down:before {
  content: "\eb6d";
}

.ai-icon-image-user-left:before {
  content: "\eb6e";
}

.ai-icon-image-user-plus:before {
  content: "\eb6f";
}

.ai-icon-image-user-right:before {
  content: "\eb70";
}

.ai-icon-image-user-up:before {
  content: "\eb71";
}

.ai-icon-image-user-x:before {
  content: "\eb72";
}

.ai-icon-image-x:before {
  content: "\eb73";
}

.ai-icon-inbox-01:before {
  content: "\eb74";
}

.ai-icon-inbox-02:before {
  content: "\eb75";
}

.ai-icon-infinity:before {
  content: "\eb76";
}

.ai-icon-info-circle:before {
  content: "\eb77";
}

.ai-icon-info-hexagon:before {
  content: "\eb78";
}

.ai-icon-info-octagon:before {
  content: "\eb79";
}

.ai-icon-info-square:before {
  content: "\eb7a";
}

.ai-icon-intersect-circle:before {
  content: "\eb7b";
}

.ai-icon-intersect-square:before {
  content: "\eb7c";
}

.ai-icon-italic-01:before {
  content: "\eb7d";
}

.ai-icon-italic-02:before {
  content: "\eb7e";
}

.ai-icon-italic-square:before {
  content: "\eb7f";
}

.ai-icon-key-01:before {
  content: "\eb80";
}

.ai-icon-key-02:before {
  content: "\eb81";
}

.ai-icon-keyboard-01:before {
  content: "\eb82";
}

.ai-icon-keyboard-02:before {
  content: "\eb83";
}

.ai-icon-laptop-01:before {
  content: "\eb84";
}

.ai-icon-laptop-02:before {
  content: "\eb85";
}

.ai-icon-layer-single:before {
  content: "\eb86";
}

.ai-icon-layers-three-01:before {
  content: "\eb87";
}

.ai-icon-layers-three-02:before {
  content: "\eb88";
}

.ai-icon-layers-two-01:before {
  content: "\eb89";
}

.ai-icon-layers-two-02:before {
  content: "\eb8a";
}

.ai-icon-layout-alt-01:before {
  content: "\eb8b";
}

.ai-icon-layout-alt-02:before {
  content: "\eb8c";
}

.ai-icon-layout-alt-03:before {
  content: "\eb8d";
}

.ai-icon-layout-alt-04:before {
  content: "\eb8e";
}

.ai-icon-layout-bottom:before {
  content: "\eb8f";
}

.ai-icon-layout-grid-01:before {
  content: "\eb90";
}

.ai-icon-layout-grid-02:before {
  content: "\eb91";
}

.ai-icon-layout-left:before {
  content: "\eb92";
}

.ai-icon-layout-right:before {
  content: "\eb93";
}

.ai-icon-layout-top:before {
  content: "\eb94";
}

.ai-icon-left-indent-01:before {
  content: "\eb95";
}

.ai-icon-left-indent-02:before {
  content: "\eb96";
}

.ai-icon-letter-spacing-01:before {
  content: "\eb97";
}

.ai-icon-letter-spacing-02:before {
  content: "\eb98";
}

.ai-icon-life-buoy-01:before {
  content: "\eb99";
}

.ai-icon-life-buoy-02:before {
  content: "\eb9a";
}

.ai-icon-lightbulb-01:before {
  content: "\eb9b";
}

.ai-icon-lightbulb-02:before {
  content: "\eb9c";
}

.ai-icon-lightbulb-03:before {
  content: "\eb9d";
}

.ai-icon-lightbulb-04:before {
  content: "\eb9e";
}

.ai-icon-lightbulb-05:before {
  content: "\eb9f";
}

.ai-icon-lightning-01:before {
  content: "\eba0";
}

.ai-icon-lightning-02:before {
  content: "\eba1";
}

.ai-icon-line-chart-down-01:before {
  content: "\eba2";
}

.ai-icon-line-chart-down-02:before {
  content: "\eba3";
}

.ai-icon-line-chart-down-03:before {
  content: "\eba4";
}

.ai-icon-line-chart-down-04:before {
  content: "\eba5";
}

.ai-icon-line-chart-down-05:before {
  content: "\eba6";
}

.ai-icon-line-chart-up-01:before {
  content: "\eba7";
}

.ai-icon-line-chart-up-02:before {
  content: "\eba8";
}

.ai-icon-line-chart-up-03:before {
  content: "\eba9";
}

.ai-icon-line-chart-up-04:before {
  content: "\ebaa";
}

.ai-icon-line-chart-up-05:before {
  content: "\ebab";
}

.ai-icon-line-height:before {
  content: "\ebac";
}

.ai-icon-link-01:before {
  content: "\ebad";
}

.ai-icon-link-02:before {
  content: "\ebae";
}

.ai-icon-link-03:before {
  content: "\ebaf";
}

.ai-icon-link-04:before {
  content: "\ebb0";
}

.ai-icon-link-05:before {
  content: "\ebb1";
}

.ai-icon-link-broken-01:before {
  content: "\ebb2";
}

.ai-icon-link-broken-02:before {
  content: "\ebb3";
}

.ai-icon-link-external-01:before {
  content: "\ebb4";
}

.ai-icon-link-external-02:before {
  content: "\ebb5";
}

.ai-icon-list:before {
  content: "\ebb6";
}

.ai-icon-loading-01:before {
  content: "\ebb7";
}

.ai-icon-loading-02:before {
  content: "\ebb8";
}

.ai-icon-loading-03:before {
  content: "\ebb9";
}

.ai-icon-lock-01:before {
  content: "\ebba";
}

.ai-icon-lock-02:before {
  content: "\ebbb";
}

.ai-icon-lock-03:before {
  content: "\ebbc";
}

.ai-icon-lock-04:before {
  content: "\ebbd";
}

.ai-icon-lock-keyhole-circle:before {
  content: "\ebbe";
}

.ai-icon-lock-keyhole-square:before {
  content: "\ebbf";
}

.ai-icon-lock-unlocked-01:before {
  content: "\ebc0";
}

.ai-icon-lock-unlocked-02:before {
  content: "\ebc1";
}

.ai-icon-lock-unlocked-03:before {
  content: "\ebc2";
}

.ai-icon-lock-unlocked-04:before {
  content: "\ebc3";
}

.ai-icon-log-in-01:before {
  content: "\ebc4";
}

.ai-icon-log-in-02:before {
  content: "\ebc5";
}

.ai-icon-log-in-03:before {
  content: "\ebc6";
}

.ai-icon-log-in-04:before {
  content: "\ebc7";
}

.ai-icon-log-out-01:before {
  content: "\ebc8";
}

.ai-icon-log-out-02:before {
  content: "\ebc9";
}

.ai-icon-log-out-03:before {
  content: "\ebca";
}

.ai-icon-log-out-04:before {
  content: "\ebcb";
}

.ai-icon-log-tracker-2:before {
  content: "\ebcc";
}

.ai-icon-luggage-01:before {
  content: "\ebcd";
}

.ai-icon-luggage-02:before {
  content: "\ebce";
}

.ai-icon-luggage-03:before {
  content: "\ebcf";
}

.ai-icon-magic-wand-01:before {
  content: "\ebd0";
}

.ai-icon-magic-wand-02:before {
  content: "\ebd1";
}

.ai-icon-mail-01:before {
  content: "\ebd2";
}

.ai-icon-mail-02:before {
  content: "\ebd3";
}

.ai-icon-mail-03:before {
  content: "\ebd4";
}

.ai-icon-mail-04:before {
  content: "\ebd5";
}

.ai-icon-mail-05:before {
  content: "\ebd6";
}

.ai-icon-map-01:before {
  content: "\ebd7";
}

.ai-icon-map-02:before {
  content: "\ebd8";
}

.ai-icon-mark:before {
  content: "\ebd9";
}

.ai-icon-marker-pin-01:before {
  content: "\ebda";
}

.ai-icon-marker-pin-02:before {
  content: "\ebdb";
}

.ai-icon-marker-pin-03:before {
  content: "\ebdc";
}

.ai-icon-marker-pin-04:before {
  content: "\ebdd";
}

.ai-icon-marker-pin-05:before {
  content: "\ebde";
}

.ai-icon-marker-pin-06:before {
  content: "\ebdf";
}

.ai-icon-maximize-01:before {
  content: "\ebe0";
}

.ai-icon-maximize-02:before {
  content: "\ebe1";
}

.ai-icon-medical-circle:before {
  content: "\ebe2";
}

.ai-icon-medical-cross:before {
  content: "\ebe3";
}

.ai-icon-medical-square:before {
  content: "\ebe4";
}

.ai-icon-menu-01:before {
  content: "\ebe5";
}

.ai-icon-menu-02:before {
  content: "\ebe6";
}

.ai-icon-menu-03:before {
  content: "\ebe7";
}

.ai-icon-menu-04:before {
  content: "\ebe8";
}

.ai-icon-menu-05:before {
  content: "\ebe9";
}

.ai-icon-message-alert-circle:before {
  content: "\ebea";
}

.ai-icon-message-alert-square:before {
  content: "\ebeb";
}

.ai-icon-message-chat-circle:before {
  content: "\ebec";
}

.ai-icon-message-chat-square:before {
  content: "\ebed";
}

.ai-icon-message-check-circle:before {
  content: "\ebee";
}

.ai-icon-message-check-square:before {
  content: "\ebef";
}

.ai-icon-message-circle-01:before {
  content: "\ebf0";
}

.ai-icon-message-circle-02:before {
  content: "\ebf1";
}

.ai-icon-message-dots-circle:before {
  content: "\ebf2";
}

.ai-icon-message-dots-square:before {
  content: "\ebf3";
}

.ai-icon-message-heart-circle:before {
  content: "\ebf4";
}

.ai-icon-message-heart-square:before {
  content: "\ebf5";
}

.ai-icon-message-notification-circle:before {
  content: "\ebf6";
}

.ai-icon-message-notification-square:before {
  content: "\ebf7";
}

.ai-icon-message-plus-circle:before {
  content: "\ebf8";
}

.ai-icon-message-plus-square:before {
  content: "\ebf9";
}

.ai-icon-message-question-circle:before {
  content: "\ebfa";
}

.ai-icon-message-question-square:before {
  content: "\ebfb";
}

.ai-icon-message-smile-circle:before {
  content: "\ebfc";
}

.ai-icon-message-smile-square:before {
  content: "\ebfd";
}

.ai-icon-message-square-01:before {
  content: "\ebfe";
}

.ai-icon-message-square-02:before {
  content: "\ebff";
}

.ai-icon-message-text-circle-01:before {
  content: "\ec00";
}

.ai-icon-message-text-circle-02:before {
  content: "\ec01";
}

.ai-icon-message-text-square-01:before {
  content: "\ec02";
}

.ai-icon-message-text-square-02:before {
  content: "\ec03";
}

.ai-icon-message-x-circle:before {
  content: "\ec04";
}

.ai-icon-message-x-square:before {
  content: "\ec05";
}

.ai-icon-microphone-01:before {
  content: "\ec06";
}

.ai-icon-microphone-02:before {
  content: "\ec07";
}

.ai-icon-microphone-off-01:before {
  content: "\ec08";
}

.ai-icon-microphone-off-02:before {
  content: "\ec09";
}

.ai-icon-microscope:before {
  content: "\ec0a";
}

.ai-icon-minimize-01:before {
  content: "\ec0b";
}

.ai-icon-minimize-02:before {
  content: "\ec0c";
}

.ai-icon-minus:before {
  content: "\ec0d";
}

.ai-icon-minus-circle:before {
  content: "\ec0e";
}

.ai-icon-minus-square:before {
  content: "\ec0f";
}

.ai-icon-modem-01:before {
  content: "\ec10";
}

.ai-icon-modem-02:before {
  content: "\ec11";
}

.ai-icon-monitor-01:before {
  content: "\ec12";
}

.ai-icon-monitor-02:before {
  content: "\ec13";
}

.ai-icon-monitor-03:before {
  content: "\ec14";
}

.ai-icon-monitor-04:before {
  content: "\ec15";
}

.ai-icon-monitor-05:before {
  content: "\ec16";
}

.ai-icon-moon-01:before {
  content: "\ec17";
}

.ai-icon-moon-02:before {
  content: "\ec18";
}

.ai-icon-moon-eclipse:before {
  content: "\ec19";
}

.ai-icon-moon-star:before {
  content: "\ec1a";
}

.ai-icon-mouse:before {
  content: "\ec1b";
}

.ai-icon-move:before {
  content: "\ec1c";
}

.ai-icon-music-note-01:before {
  content: "\ec1d";
}

.ai-icon-music-note-02:before {
  content: "\ec1e";
}

.ai-icon-music-note-plus:before {
  content: "\ec1f";
}

.ai-icon-navigation-pointer-01:before {
  content: "\ec20";
}

.ai-icon-navigation-pointer-02:before {
  content: "\ec21";
}

.ai-icon-navigation-pointer-off-01:before {
  content: "\ec22";
}

.ai-icon-navigation-pointer-off-02:before {
  content: "\ec23";
}

.ai-icon-notification-box:before {
  content: "\ec24";
}

.ai-icon-notification-message:before {
  content: "\ec25";
}

.ai-icon-notification-text:before {
  content: "\ec26";
}

.ai-icon-octagon:before {
  content: "\ec27";
}

.ai-icon-package:before {
  content: "\ec28";
}

.ai-icon-package-check:before {
  content: "\ec29";
}

.ai-icon-package-minus:before {
  content: "\ec2a";
}

.ai-icon-package-plus:before {
  content: "\ec2b";
}

.ai-icon-package-search:before {
  content: "\ec2c";
}

.ai-icon-package-x:before {
  content: "\ec2d";
}

.ai-icon-paint:before {
  content: "\ec2e";
}

.ai-icon-paint-pour:before {
  content: "\ec2f";
}

.ai-icon-palette:before {
  content: "\ec30";
}

.ai-icon-paperclip:before {
  content: "\ec31";
}

.ai-icon-paragraph-spacing:before {
  content: "\ec32";
}

.ai-icon-paragraph-wrap:before {
  content: "\ec33";
}

.ai-icon-passcode:before {
  content: "\ec34";
}

.ai-icon-passcode-lock:before {
  content: "\ec35";
}

.ai-icon-passport:before {
  content: "\ec36";
}

.ai-icon-pause-circle:before {
  content: "\ec37";
}

.ai-icon-pause-square:before {
  content: "\ec38";
}

.ai-icon-pencil-01:before {
  content: "\ec39";
}

.ai-icon-pencil-02:before {
  content: "\ec3a";
}

.ai-icon-pencil-line:before {
  content: "\ec3b";
}

.ai-icon-pentagon:before {
  content: "\ec3c";
}

.ai-icon-pen-tool-01:before {
  content: "\ec3d";
}

.ai-icon-pen-tool-02:before {
  content: "\ec3e";
}

.ai-icon-pen-tool-minus:before {
  content: "\ec3f";
}

.ai-icon-pen-tool-plus:before {
  content: "\ec40";
}

.ai-icon-percent-01:before {
  content: "\ec41";
}

.ai-icon-percent-02:before {
  content: "\ec42";
}

.ai-icon-percent-03:before {
  content: "\ec43";
}

.ai-icon-perspective-01:before {
  content: "\ec44";
}

.ai-icon-perspective-02:before {
  content: "\ec45";
}

.ai-icon-phone:before {
  content: "\ec46";
}

.ai-icon-phone-01:before {
  content: "\ec47";
}

.ai-icon-phone-02:before {
  content: "\ec48";
}

.ai-icon-phone-call-01:before {
  content: "\ec49";
}

.ai-icon-phone-call-02:before {
  content: "\ec4a";
}

.ai-icon-phone-hang-up:before {
  content: "\ec4b";
}

.ai-icon-phone-incoming-01:before {
  content: "\ec4c";
}

.ai-icon-phone-incoming-02:before {
  content: "\ec4d";
}

.ai-icon-phone-outgoing-01:before {
  content: "\ec4e";
}

.ai-icon-phone-outgoing-02:before {
  content: "\ec4f";
}

.ai-icon-phone-pause:before {
  content: "\ec50";
}

.ai-icon-phone-plus:before {
  content: "\ec51";
}

.ai-icon-phone-x:before {
  content: "\ec52";
}

.ai-icon-pie-chart-01:before {
  content: "\ec53";
}

.ai-icon-pie-chart-02:before {
  content: "\ec54";
}

.ai-icon-pie-chart-03:before {
  content: "\ec55";
}

.ai-icon-pie-chart-04:before {
  content: "\ec56";
}

.ai-icon-piggy-bank-01:before {
  content: "\ec57";
}

.ai-icon-piggy-bank-02:before {
  content: "\ec58";
}

.ai-icon-pilcrow-01:before {
  content: "\ec59";
}

.ai-icon-pilcrow-02:before {
  content: "\ec5a";
}

.ai-icon-pilcrow-square:before {
  content: "\ec5b";
}

.ai-icon-pill:before {
  content: "\ec5c";
}

.ai-icon-pin-01:before {
  content: "\ec5d";
}

.ai-icon-pin-02:before {
  content: "\ec5e";
}

.ai-icon-placeholder:before {
  content: "\ec5f";
}

.ai-icon-plane:before {
  content: "\ec60";
}

.ai-icon-play:before {
  content: "\ec61";
}

.ai-icon-play-circle:before {
  content: "\ec62";
}

.ai-icon-play-square:before {
  content: "\ec63";
}

.ai-icon-plus:before {
  content: "\ec64";
}

.ai-icon-plus-circle:before {
  content: "\ec65";
}

.ai-icon-plus-square:before {
  content: "\ec66";
}

.ai-icon-podcast:before {
  content: "\ec67";
}

.ai-icon-power-01:before {
  content: "\ec68";
}

.ai-icon-power-02:before {
  content: "\ec69";
}

.ai-icon-power-03:before {
  content: "\ec6a";
}

.ai-icon-presentation-chart-01:before {
  content: "\ec6b";
}

.ai-icon-presentation-chart-02:before {
  content: "\ec6c";
}

.ai-icon-presentation-chart-03:before {
  content: "\ec6d";
}

.ai-icon-printer:before {
  content: "\ec6e";
}

.ai-icon-puzzle-piece-01:before {
  content: "\ec6f";
}

.ai-icon-puzzle-piece-02:before {
  content: "\ec70";
}

.ai-icon-qr-code-01:before {
  content: "\ec71";
}

.ai-icon-qr-code-02:before {
  content: "\ec72";
}

.ai-icon-receipt:before {
  content: "\ec73";
}

.ai-icon-receipt-check:before {
  content: "\ec74";
}

.ai-icon-recording-01:before {
  content: "\ec75";
}

.ai-icon-recording-02:before {
  content: "\ec76";
}

.ai-icon-recording-03:before {
  content: "\ec77";
}

.ai-icon-reflect-01:before {
  content: "\ec78";
}

.ai-icon-reflect-02:before {
  content: "\ec79";
}

.ai-icon-refresh-ccw-01:before {
  content: "\ec7a";
}

.ai-icon-refresh-ccw-02:before {
  content: "\ec7b";
}

.ai-icon-refresh-ccw-03:before {
  content: "\ec7c";
}

.ai-icon-refresh-ccw-04:before {
  content: "\ec7d";
}

.ai-icon-refresh-ccw-05:before {
  content: "\ec7e";
}

.ai-icon-refresh-cw-01:before {
  content: "\ec7f";
}

.ai-icon-refresh-cw-02:before {
  content: "\ec80";
}

.ai-icon-refresh-cw-03:before {
  content: "\ec81";
}

.ai-icon-refresh-cw-04:before {
  content: "\ec82";
}

.ai-icon-refresh-cw-05:before {
  content: "\ec83";
}

.ai-icon-repeat-01:before {
  content: "\ec84";
}

.ai-icon-repeat-02:before {
  content: "\ec85";
}

.ai-icon-repeat-03:before {
  content: "\ec86";
}

.ai-icon-repeat-04:before {
  content: "\ec87";
}

.ai-icon-reverse-left:before {
  content: "\ec88";
}

.ai-icon-reverse-right:before {
  content: "\ec89";
}

.ai-icon-right-indent-01:before {
  content: "\ec8a";
}

.ai-icon-right-indent-02:before {
  content: "\ec8b";
}

.ai-icon-rocket-01:before {
  content: "\ec8c";
}

.ai-icon-rocket-02:before {
  content: "\ec8d";
}

.ai-icon-roller-brush:before {
  content: "\ec8e";
}

.ai-icon-route:before {
  content: "\ec8f";
}

.ai-icon-rows-01:before {
  content: "\ec90";
}

.ai-icon-rows-02:before {
  content: "\ec91";
}

.ai-icon-rows-03:before {
  content: "\ec92";
}

.ai-icon-rss-01:before {
  content: "\ec93";
}

.ai-icon-rss-02:before {
  content: "\ec94";
}

.ai-icon-ruler:before {
  content: "\ec95";
}

.ai-icon-safe:before {
  content: "\ec96";
}

.ai-icon-sale-01:before {
  content: "\ec97";
}

.ai-icon-sale-02:before {
  content: "\ec98";
}

.ai-icon-sale-03:before {
  content: "\ec99";
}

.ai-icon-sale-04:before {
  content: "\ec9a";
}

.ai-icon-save-01:before {
  content: "\ec9b";
}

.ai-icon-save-02:before {
  content: "\ec9c";
}

.ai-icon-save-03:before {
  content: "\ec9d";
}

.ai-icon-scale-01:before {
  content: "\ec9e";
}

.ai-icon-scale-02:before {
  content: "\ec9f";
}

.ai-icon-scale-03:before {
  content: "\eca0";
}

.ai-icon-scales-01:before {
  content: "\eca1";
}

.ai-icon-scales-02:before {
  content: "\eca2";
}

.ai-icon-scan:before {
  content: "\eca3";
}

.ai-icon-scissors-01:before {
  content: "\eca4";
}

.ai-icon-scissors-02:before {
  content: "\eca5";
}

.ai-icon-scissors-cut-01:before {
  content: "\eca6";
}

.ai-icon-scissors-cut-02:before {
  content: "\eca7";
}

.ai-icon-search-lg:before {
  content: "\eca8";
}

.ai-icon-search-md:before {
  content: "\eca9";
}

.ai-icon-search-refraction:before {
  content: "\ecaa";
}

.ai-icon-search-sm:before {
  content: "\ecab";
}

.ai-icon-send-01:before {
  content: "\ecac";
}

.ai-icon-send-02:before {
  content: "\ecad";
}

.ai-icon-send-03:before {
  content: "\ecae";
}

.ai-icon-server-01:before {
  content: "\ecaf";
}

.ai-icon-server-02:before {
  content: "\ecb0";
}

.ai-icon-server-03:before {
  content: "\ecb1";
}

.ai-icon-server-04:before {
  content: "\ecb2";
}

.ai-icon-server-05:before {
  content: "\ecb3";
}

.ai-icon-server-06:before {
  content: "\ecb4";
}

.ai-icon-settings-01:before {
  content: "\ecb5";
}

.ai-icon-settings-02:before {
  content: "\ecb6";
}

.ai-icon-settings-03:before {
  content: "\ecb7";
}

.ai-icon-settings-04:before {
  content: "\ecb8";
}

.ai-icon-share-01:before {
  content: "\ecb9";
}

.ai-icon-share-02:before {
  content: "\ecba";
}

.ai-icon-share-03:before {
  content: "\ecbb";
}

.ai-icon-share-04:before {
  content: "\ecbc";
}

.ai-icon-share-05:before {
  content: "\ecbd";
}

.ai-icon-share-06:before {
  content: "\ecbe";
}

.ai-icon-share-07:before {
  content: "\ecbf";
}

.ai-icon-shield-01:before {
  content: "\ecc0";
}

.ai-icon-shield-02:before {
  content: "\ecc1";
}

.ai-icon-shield-03:before {
  content: "\ecc2";
}

.ai-icon-shield-dollar:before {
  content: "\ecc3";
}

.ai-icon-shield-off:before {
  content: "\ecc4";
}

.ai-icon-shield-plus:before {
  content: "\ecc5";
}

.ai-icon-shield-tick:before {
  content: "\ecc6";
}

.ai-icon-shield-zap:before {
  content: "\ecc7";
}

.ai-icon-shopping-bag-01:before {
  content: "\ecc8";
}

.ai-icon-shopping-bag-02:before {
  content: "\ecc9";
}

.ai-icon-shopping-bag-03:before {
  content: "\ecca";
}

.ai-icon-shopping-cart-01:before {
  content: "\eccb";
}

.ai-icon-shopping-cart-02:before {
  content: "\eccc";
}

.ai-icon-shopping-cart-03:before {
  content: "\eccd";
}

.ai-icon-shuffle-01:before {
  content: "\ecce";
}

.ai-icon-shuffle-02:before {
  content: "\eccf";
}

.ai-icon-signal-01:before {
  content: "\ecd0";
}

.ai-icon-signal-02:before {
  content: "\ecd1";
}

.ai-icon-signal-03:before {
  content: "\ecd2";
}

.ai-icon-signature-disabled:before {
  content: "\ecd3";
}

.ai-icon-signature-user:before {
  content: "\ecd4";
}

.ai-icon-signature-user-pencil:before {
  content: "\ecd5";
}

.ai-icon-signature-users:before {
  content: "\ecd6";
}

.ai-icon-simcard:before {
  content: "\ecd7";
}

.ai-icon-skew:before {
  content: "\ecd8";
}

.ai-icon-skip-back:before {
  content: "\ecd9";
}

.ai-icon-skip-forward:before {
  content: "\ecda";
}

.ai-icon-slash-circle-01:before {
  content: "\ecdb";
}

.ai-icon-slash-circle-02:before {
  content: "\ecdc";
}

.ai-icon-slash-divider:before {
  content: "\ecdd";
}

.ai-icon-slash-octagon:before {
  content: "\ecde";
}

.ai-icon-sliders-01:before {
  content: "\ecdf";
}

.ai-icon-sliders-02:before {
  content: "\ece0";
}

.ai-icon-sliders-03:before {
  content: "\ece1";
}

.ai-icon-sliders-04:before {
  content: "\ece2";
}

.ai-icon-snowflake-01:before {
  content: "\ece3";
}

.ai-icon-snowflake-02:before {
  content: "\ece4";
}

.ai-icon-spacing-height-01:before {
  content: "\ece5";
}

.ai-icon-spacing-height-02:before {
  content: "\ece6";
}

.ai-icon-spacing-width-01:before {
  content: "\ece7";
}

.ai-icon-spacing-width-02:before {
  content: "\ece8";
}

.ai-icon-speaker-01:before {
  content: "\ece9";
}

.ai-icon-speaker-02:before {
  content: "\ecea";
}

.ai-icon-speaker-03:before {
  content: "\eceb";
}

.ai-icon-speedometer-01:before {
  content: "\ecec";
}

.ai-icon-speedometer-02:before {
  content: "\eced";
}

.ai-icon-speedometer-03:before {
  content: "\ecee";
}

.ai-icon-speedometer-04:before {
  content: "\ecef";
}

.ai-icon-square:before {
  content: "\ecf0";
}

.ai-icon-stand:before {
  content: "\ecf1";
}

.ai-icon-star-01:before {
  content: "\ecf2";
}

.ai-icon-star-02:before {
  content: "\ecf3";
}

.ai-icon-star-03:before {
  content: "\ecf4";
}

.ai-icon-star-04:before {
  content: "\ecf5";
}

.ai-icon-star-05:before {
  content: "\ecf6";
}

.ai-icon-star-06:before {
  content: "\ecf7";
}

.ai-icon-star-07:before {
  content: "\ecf8";
}

.ai-icon-stars-01:before {
  content: "\ecf9";
}

.ai-icon-stars-02:before {
  content: "\ecfa";
}

.ai-icon-stars-03:before {
  content: "\ecfb";
}

.ai-icon-sticker-circle:before {
  content: "\ecfc";
}

.ai-icon-sticker-square:before {
  content: "\ecfd";
}

.ai-icon-stop:before {
  content: "\ecfe";
}

.ai-icon-stop-circle:before {
  content: "\ecff";
}

.ai-icon-stop-square:before {
  content: "\ed00";
}

.ai-icon-strikethrough-01:before {
  content: "\ed01";
}

.ai-icon-strikethrough-02:before {
  content: "\ed02";
}

.ai-icon-strikethrough-square:before {
  content: "\ed03";
}

.ai-icon-subscript:before {
  content: "\ed04";
}

.ai-icon-sun:before {
  content: "\ed05";
}

.ai-icon-sunrise:before {
  content: "\ed06";
}

.ai-icon-sunset:before {
  content: "\ed07";
}

.ai-icon-sun-setting-01:before {
  content: "\ed08";
}

.ai-icon-sun-setting-02:before {
  content: "\ed09";
}

.ai-icon-sun-setting-03:before {
  content: "\ed0a";
}

.ai-icon-switch-horizontal-01:before {
  content: "\ed0b";
}

.ai-icon-switch-horizontal-02:before {
  content: "\ed0c";
}

.ai-icon-switch-vertical-01:before {
  content: "\ed0d";
}

.ai-icon-switch-vertical-02:before {
  content: "\ed0e";
}

.ai-icon-table:before {
  content: "\ed0f";
}

.ai-icon-tablet-01:before {
  content: "\ed10";
}

.ai-icon-tablet-02:before {
  content: "\ed11";
}

.ai-icon-tablet-02-checked:before {
  content: "\ed12";
}

.ai-icon-tag-01:before {
  content: "\ed13";
}

.ai-icon-tag-02:before {
  content: "\ed14";
}

.ai-icon-tag-03:before {
  content: "\ed15";
}

.ai-icon-target-01:before {
  content: "\ed16";
}

.ai-icon-target-02:before {
  content: "\ed17";
}

.ai-icon-target-03:before {
  content: "\ed18";
}

.ai-icon-target-04:before {
  content: "\ed19";
}

.ai-icon-target-05:before {
  content: "\ed1a";
}

.ai-icon-telescope:before {
  content: "\ed1b";
}

.ai-icon-terminal:before {
  content: "\ed1c";
}

.ai-icon-terminal-browser:before {
  content: "\ed1d";
}

.ai-icon-terminal-circle:before {
  content: "\ed1e";
}

.ai-icon-terminal-square:before {
  content: "\ed1f";
}

.ai-icon-text-input:before {
  content: "\ed20";
}

.ai-icon-thermometer-01:before {
  content: "\ed21";
}

.ai-icon-thermometer-02:before {
  content: "\ed22";
}

.ai-icon-thermometer-03:before {
  content: "\ed23";
}

.ai-icon-thermometer-cold:before {
  content: "\ed24";
}

.ai-icon-thermometer-warm:before {
  content: "\ed25";
}

.ai-icon-thumbs-down:before {
  content: "\ed26";
}

.ai-icon-thumbs-up:before {
  content: "\ed27";
}

.ai-icon-ticket-01:before {
  content: "\ed28";
}

.ai-icon-ticket-02:before {
  content: "\ed29";
}

.ai-icon-toggle-01-left:before {
  content: "\ed2a";
}

.ai-icon-toggle-01-right:before {
  content: "\ed2b";
}

.ai-icon-toggle-02-left:before {
  content: "\ed2c";
}

.ai-icon-toggle-02-right:before {
  content: "\ed2d";
}

.ai-icon-toggle-03-left:before {
  content: "\ed2e";
}

.ai-icon-toggle-03-right:before {
  content: "\ed2f";
}

.ai-icon-tool-01:before {
  content: "\ed30";
}

.ai-icon-tool-02:before {
  content: "\ed31";
}

.ai-icon-train:before {
  content: "\ed32";
}

.ai-icon-tram:before {
  content: "\ed33";
}

.ai-icon-transform:before {
  content: "\ed34";
}

.ai-icon-translate-01:before {
  content: "\ed35";
}

.ai-icon-translate-02:before {
  content: "\ed36";
}

.ai-icon-trash-01:before {
  content: "\ed37";
}

.ai-icon-trash-02:before {
  content: "\ed38";
}

.ai-icon-trash-03:before {
  content: "\ed39";
}

.ai-icon-trash-04:before {
  content: "\ed3a";
}

.ai-icon-trend-down-01:before {
  content: "\ed3b";
}

.ai-icon-trend-down-02:before {
  content: "\ed3c";
}

.ai-icon-trend-up-01:before {
  content: "\ed3d";
}

.ai-icon-trend-up-02:before {
  content: "\ed3e";
}

.ai-icon-triangle:before {
  content: "\ed3f";
}

.ai-icon-trophy-01:before {
  content: "\ed40";
}

.ai-icon-trophy-02:before {
  content: "\ed41";
}

.ai-icon-truck-01:before {
  content: "\ed42";
}

.ai-icon-truck-02:before {
  content: "\ed43";
}

.ai-icon-tv-01:before {
  content: "\ed44";
}

.ai-icon-tv-02:before {
  content: "\ed45";
}

.ai-icon-tv-03:before {
  content: "\ed46";
}

.ai-icon-type-01:before {
  content: "\ed47";
}

.ai-icon-type-02:before {
  content: "\ed48";
}

.ai-icon-type-square:before {
  content: "\ed49";
}

.ai-icon-type-strikethrough-01:before {
  content: "\ed4a";
}

.ai-icon-type-strikethrough-02:before {
  content: "\ed4b";
}

.ai-icon-umbrella-01:before {
  content: "\ed4c";
}

.ai-icon-umbrella-02:before {
  content: "\ed4d";
}

.ai-icon-umbrella-03:before {
  content: "\ed4e";
}

.ai-icon-underline-01:before {
  content: "\ed4f";
}

.ai-icon-underline-02:before {
  content: "\ed50";
}

.ai-icon-underline-square:before {
  content: "\ed51";
}

.ai-icon-upload-01:before {
  content: "\ed52";
}

.ai-icon-upload-02:before {
  content: "\ed53";
}

.ai-icon-upload-03:before {
  content: "\ed54";
}

.ai-icon-upload-04:before {
  content: "\ed55";
}

.ai-icon-upload-cloud-01:before {
  content: "\ed56";
}

.ai-icon-upload-cloud-02:before {
  content: "\ed57";
}

.ai-icon-usb-flash-drive:before {
  content: "\ed58";
}

.ai-icon-user-01:before {
  content: "\ed59";
}

.ai-icon-user-02:before {
  content: "\ed5a";
}

.ai-icon-user-03:before {
  content: "\ed5b";
}

.ai-icon-user-check-01:before {
  content: "\ed5c";
}

.ai-icon-user-check-02:before {
  content: "\ed5d";
}

.ai-icon-user-circle:before {
  content: "\ed5e";
}

.ai-icon-user-down-01:before {
  content: "\ed5f";
}

.ai-icon-user-down-02:before {
  content: "\ed60";
}

.ai-icon-user-edit:before {
  content: "\ed61";
}

.ai-icon-user-left-01:before {
  content: "\ed62";
}

.ai-icon-user-left-02:before {
  content: "\ed63";
}

.ai-icon-user-minus-01:before {
  content: "\ed64";
}

.ai-icon-user-minus-02:before {
  content: "\ed65";
}

.ai-icon-user-plus-01:before {
  content: "\ed66";
}

.ai-icon-user-plus-02:before {
  content: "\ed67";
}

.ai-icon-user-right-01:before {
  content: "\ed68";
}

.ai-icon-user-right-02:before {
  content: "\ed69";
}

.ai-icon-users-01:before {
  content: "\ed6a";
}

.ai-icon-users-02:before {
  content: "\ed6b";
}

.ai-icon-users-03:before {
  content: "\ed6c";
}

.ai-icon-users-check:before {
  content: "\ed6d";
}

.ai-icon-users-down:before {
  content: "\ed6e";
}

.ai-icon-users-edit:before {
  content: "\ed6f";
}

.ai-icon-user-signature:before {
  content: "\ed70";
}

.ai-icon-users-left:before {
  content: "\ed71";
}

.ai-icon-users-minus:before {
  content: "\ed72";
}

.ai-icon-users-plus:before {
  content: "\ed73";
}

.ai-icon-user-square:before {
  content: "\ed74";
}

.ai-icon-users-right:before {
  content: "\ed75";
}

.ai-icon-users-up:before {
  content: "\ed76";
}

.ai-icon-users-x:before {
  content: "\ed77";
}

.ai-icon-user-up-01:before {
  content: "\ed78";
}

.ai-icon-user-up-02:before {
  content: "\ed79";
}

.ai-icon-user-x-01:before {
  content: "\ed7a";
}

.ai-icon-user-x-02:before {
  content: "\ed7b";
}

.ai-icon-variable:before {
  content: "\ed7c";
}

.ai-icon-video-recorder:before {
  content: "\ed7d";
}

.ai-icon-video-recorder-off:before {
  content: "\ed7e";
}

.ai-icon-virus:before {
  content: "\ed7f";
}

.ai-icon-voicemail:before {
  content: "\ed80";
}

.ai-icon-volume-max:before {
  content: "\ed81";
}

.ai-icon-volume-min:before {
  content: "\ed82";
}

.ai-icon-volume-minus:before {
  content: "\ed83";
}

.ai-icon-volume-plus:before {
  content: "\ed84";
}

.ai-icon-volume-x:before {
  content: "\ed85";
}

.ai-icon-wallet-01:before {
  content: "\ed86";
}

.ai-icon-wallet-02:before {
  content: "\ed87";
}

.ai-icon-wallet-03:before {
  content: "\ed88";
}

.ai-icon-wallet-04:before {
  content: "\ed89";
}

.ai-icon-wallet-05:before {
  content: "\ed8a";
}

.ai-icon-watch-circle:before {
  content: "\ed8b";
}

.ai-icon-watch-square:before {
  content: "\ed8c";
}

.ai-icon-waves:before {
  content: "\ed8d";
}

.ai-icon-webcam-01:before {
  content: "\ed8e";
}

.ai-icon-webcam-02:before {
  content: "\ed8f";
}

.ai-icon-wifi:before {
  content: "\ed90";
}

.ai-icon-wifi-off:before {
  content: "\ed91";
}

.ai-icon-wind-01:before {
  content: "\ed92";
}

.ai-icon-wind-02:before {
  content: "\ed93";
}

.ai-icon-wind-03:before {
  content: "\ed94";
}

.ai-icon-x:before {
  content: "\ed95";
}

.ai-icon-x-circle:before {
  content: "\ed96";
}

.ai-icon-x-close:before {
  content: "\ed97";
}

.ai-icon-x-square:before {
  content: "\ed98";
}

.ai-icon-youtube:before {
  content: "\ed99";
}

.ai-icon-zap:before {
  content: "\ed9a";
}

.ai-icon-zap-circle:before {
  content: "\ed9b";
}

.ai-icon-zap-fast:before {
  content: "\ed9c";
}

.ai-icon-zap-off:before {
  content: "\ed9d";
}

.ai-icon-zap-square:before {
  content: "\ed9e";
}

.ai-icon-zoom-in:before {
  content: "\ed9f";
}

.ai-icon-zoom-out:before {
  content: "\eda0";
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.container * {
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (max-width: 0) {
  .col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (max-width: 0) {
  .col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

@media (max-width: 0) {
  .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 0) {
  .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

@media (max-width: 0) {
  .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

@media (max-width: 0) {
  .col-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 0) {
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

@media (max-width: 0) {
  .col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

@media (max-width: 0) {
  .col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

@media (max-width: 0) {
  .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

@media (max-width: 0) {
  .col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

@media (max-width: 0) {
  .col-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 0) {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-sm-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (max-width: 576px) {
  .col-sm {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (max-width: 576px) {
  .col-sm-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

@media (max-width: 576px) {
  .col-sm-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 576px) {
  .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

@media (max-width: 576px) {
  .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

@media (max-width: 576px) {
  .col-sm-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 576px) {
  .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

@media (max-width: 576px) {
  .col-sm-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

@media (max-width: 576px) {
  .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

@media (max-width: 576px) {
  .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

@media (max-width: 576px) {
  .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

@media (max-width: 576px) {
  .col-sm-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-md-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (max-width: 768px) {
  .col-md {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (max-width: 768px) {
  .col-md-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

@media (max-width: 768px) {
  .col-md-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 768px) {
  .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

@media (max-width: 768px) {
  .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

@media (max-width: 768px) {
  .col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

@media (max-width: 768px) {
  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

@media (max-width: 768px) {
  .col-md-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

@media (max-width: 768px) {
  .col-md-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

@media (max-width: 768px) {
  .col-md-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-lg-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (max-width: 992px) {
  .col-lg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (max-width: 992px) {
  .col-lg-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

@media (max-width: 992px) {
  .col-lg-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 992px) {
  .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

@media (max-width: 992px) {
  .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

@media (max-width: 992px) {
  .col-lg-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 992px) {
  .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

@media (max-width: 992px) {
  .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

@media (max-width: 992px) {
  .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

@media (max-width: 992px) {
  .col-lg-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

@media (max-width: 992px) {
  .col-lg-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

@media (max-width: 992px) {
  .col-lg-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 992px) {
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-xl-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (max-width: 1200px) {
  .col-xl {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (max-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

@media (max-width: 1200px) {
  .col-xl-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

@media (max-width: 1200px) {
  .col-xl-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

@media (max-width: 1200px) {
  .col-xl-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 1200px) {
  .col-xl-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

@media (max-width: 1200px) {
  .col-xl-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

@media (max-width: 1200px) {
  .col-xl-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

@media (max-width: 1200px) {
  .col-xl-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

@media (max-width: 1200px) {
  .col-xl-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

@media (max-width: 1200px) {
  .col-xl-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xl-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-xxl-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (max-width: 1400px) {
  .col-xxl {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (max-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

@media (max-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (max-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

@media (max-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

@media (max-width: 1400px) {
  .col-xxl-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 1400px) {
  .col-xxl-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

@media (max-width: 1400px) {
  .col-xxl-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

@media (max-width: 1400px) {
  .col-xxl-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

@media (max-width: 1400px) {
  .col-xxl-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

@media (max-width: 1400px) {
  .col-xxl-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

@media (max-width: 1400px) {
  .col-xxl-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-xxl-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (max-width: 1400px) {
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr) !important;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, 1fr) !important;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, 1fr) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, 1fr) !important;
}

.grid-cols-9 {
  grid-template-columns: repeat(9, 1fr) !important;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, 1fr) !important;
}

.grid-cols-11 {
  grid-template-columns: repeat(11, 1fr) !important;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, 1fr) !important;
}

@media (min-width: 576px) {
  .grid-cols-sm-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-5 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-7 {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-9 {
    grid-template-columns: repeat(9, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-10 {
    grid-template-columns: repeat(10, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-11 {
    grid-template-columns: repeat(11, 1fr) !important;
  }
}
@media (min-width: 576px) {
  .grid-cols-sm-12 {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-5 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-7 {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-9 {
    grid-template-columns: repeat(9, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-10 {
    grid-template-columns: repeat(10, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-11 {
    grid-template-columns: repeat(11, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-12 {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-5 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-7 {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-9 {
    grid-template-columns: repeat(9, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-10 {
    grid-template-columns: repeat(10, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-11 {
    grid-template-columns: repeat(11, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .grid-cols-lg-12 {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-5 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-7 {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-9 {
    grid-template-columns: repeat(9, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-10 {
    grid-template-columns: repeat(10, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-11 {
    grid-template-columns: repeat(11, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .grid-cols-xl-12 {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-5 {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-7 {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-9 {
    grid-template-columns: repeat(9, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-10 {
    grid-template-columns: repeat(10, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-11 {
    grid-template-columns: repeat(11, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .grid-cols-xxl-12 {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .p-sm-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 992px) {
  .p-lg-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) {
  .p-lg-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .p-lg-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .p-lg-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) {
  .p-lg-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .p-lg-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .p-xl-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .p-xl-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .p-xl-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .p-xl-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .p-xl-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .p-xl-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .p-xxl-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1400px) {
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .p-xxl-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .p-xxl-5 {
    padding: 3rem !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-5 {
    padding-top: 3rem !important;
  }
}
@media (min-width: 992px) {
  .pt-lg-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) {
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) {
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-xl-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1400px) {
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
}
.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

@media (min-width: 576px) {
  .pe-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) {
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) {
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 768px) {
  .pe-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pe-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pe-md-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 992px) {
  .pe-lg-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) {
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) {
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .pe-xl-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1400px) {
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 992px) {
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) {
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) {
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1400px) {
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
}
.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .ps-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) {
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) {
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .ps-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .ps-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .ps-lg-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) {
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .ps-xl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1400px) {
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

@media (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media (min-width: 992px) {
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) {
  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) {
  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1400px) {
  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

@media (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 992px) {
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) {
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) {
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1400px) {
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .m-sm-5 {
    margin: 3rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-5 {
    margin: 3rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) {
  .m-lg-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-5 {
    margin: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-5 {
    margin: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-5 {
    margin: 3rem !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 3rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
}
.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

@media (min-width: 576px) {
  .me-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) {
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .me-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) {
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .me-sm-5 {
    margin-right: 3rem !important;
  }
}
@media (min-width: 768px) {
  .me-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .me-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .me-md-5 {
    margin-right: 3rem !important;
  }
}
@media (min-width: 992px) {
  .me-lg-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) {
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .me-lg-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) {
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .me-lg-5 {
    margin-right: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-5 {
    margin-right: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
}
.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

@media (min-width: 576px) {
  .ms-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) {
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) {
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .ms-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .ms-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ms-md-5 {
    margin-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

@media (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

@media (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 992px) {
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) {
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) {
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
.m-auto {
  margin: auto !important;
}

@media (min-width: 576px) {
  .m-sm-auto {
    margin: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-auto {
    margin: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-auto {
    margin: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-auto {
    margin: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-auto {
    margin: auto !important;
  }
}
.mt-auto {
  margin-top: auto !important;
}

@media (min-width: 576px) {
  .mt-sm-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 768px) {
  .mt-md-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-auto {
    margin-top: auto !important;
  }
}
@media (min-width: 1400px) {
  .mt-xxl-auto {
    margin-top: auto !important;
  }
}
.me-auto {
  margin-right: auto !important;
}

@media (min-width: 576px) {
  .me-sm-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 768px) {
  .me-md-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 992px) {
  .me-lg-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 1200px) {
  .me-xl-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 1400px) {
  .me-xxl-auto {
    margin-right: auto !important;
  }
}
.mb-auto {
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 768px) {
  .mb-md-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 992px) {
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 1400px) {
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
}
.ms-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .ms-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .ms-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .ms-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .ms-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .ms-xxl-auto {
    margin-left: auto !important;
  }
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 576px) {
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 768px) {
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 992px) {
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 1400px) {
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 768px) {
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 992px) {
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 1400px) {
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

@media (min-width: 576px) {
  .gap-sm-0 {
    gap: 0 !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-3 {
    gap: 1rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .gap-sm-5 {
    gap: 3rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0 !important;
  }
}
@media (min-width: 768px) {
  .gap-md-1 {
    gap: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-2 {
    gap: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-3 {
    gap: 1rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-4 {
    gap: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-5 {
    gap: 3rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-0 {
    gap: 0 !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-3 {
    gap: 1rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .gap-lg-5 {
    gap: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-0 {
    gap: 0 !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-3 {
    gap: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-xl-5 {
    gap: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-0 {
    gap: 0 !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-3 {
    gap: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-xxl-5 {
    gap: 3rem !important;
  }
}
.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

@media (min-width: 576px) {
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
}
@media (min-width: 576px) {
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
}
@media (min-width: 576px) {
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
}
@media (min-width: 768px) {
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
}
@media (min-width: 768px) {
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
}
@media (min-width: 768px) {
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
}
@media (min-width: 992px) {
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
}
@media (min-width: 992px) {
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
}
@media (min-width: 992px) {
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
}
@media (min-width: 1200px) {
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
}
@media (min-width: 1400px) {
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
}
.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

@media (min-width: 576px) {
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
}
@media (min-width: 576px) {
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
}
@media (min-width: 576px) {
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
}
@media (min-width: 576px) {
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
}
@media (min-width: 576px) {
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
}
@media (min-width: 576px) {
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
}
@media (min-width: 768px) {
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
}
@media (min-width: 768px) {
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
}
@media (min-width: 768px) {
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
}
@media (min-width: 992px) {
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
}
@media (min-width: 992px) {
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
}
@media (min-width: 992px) {
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
}
@media (min-width: 992px) {
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
}
@media (min-width: 992px) {
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
}
@media (min-width: 1200px) {
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
}
@media (min-width: 1400px) {
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
}
@media (min-width: 1400px) {
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
}
@media (min-width: 1400px) {
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
}
@media (min-width: 1400px) {
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
}
@media (min-width: 1400px) {
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
}
.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

@media (min-width: 576px) {
  .order-sm-first {
    order: -1 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-0 {
    order: 0 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-1 {
    order: 1 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-2 {
    order: 2 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-3 {
    order: 3 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-4 {
    order: 4 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-5 {
    order: 5 !important;
  }
}
@media (min-width: 576px) {
  .order-sm-last {
    order: 6 !important;
  }
}
@media (min-width: 768px) {
  .order-md-first {
    order: -1 !important;
  }
}
@media (min-width: 768px) {
  .order-md-0 {
    order: 0 !important;
  }
}
@media (min-width: 768px) {
  .order-md-1 {
    order: 1 !important;
  }
}
@media (min-width: 768px) {
  .order-md-2 {
    order: 2 !important;
  }
}
@media (min-width: 768px) {
  .order-md-3 {
    order: 3 !important;
  }
}
@media (min-width: 768px) {
  .order-md-4 {
    order: 4 !important;
  }
}
@media (min-width: 768px) {
  .order-md-5 {
    order: 5 !important;
  }
}
@media (min-width: 768px) {
  .order-md-last {
    order: 6 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-first {
    order: -1 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-0 {
    order: 0 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-1 {
    order: 1 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-2 {
    order: 2 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-3 {
    order: 3 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-4 {
    order: 4 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-5 {
    order: 5 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-last {
    order: 6 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-first {
    order: -1 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-0 {
    order: 0 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-1 {
    order: 1 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-2 {
    order: 2 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-3 {
    order: 3 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-4 {
    order: 4 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-5 {
    order: 5 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-last {
    order: 6 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-first {
    order: -1 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-0 {
    order: 0 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-1 {
    order: 1 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-2 {
    order: 2 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-3 {
    order: 3 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-4 {
    order: 4 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-5 {
    order: 5 !important;
  }
}
@media (min-width: 1400px) {
  .order-xxl-last {
    order: 6 !important;
  }
}
.flex-fill {
  flex: 1 1 auto !important;
}

@media (min-width: 576px) {
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
}
@media (min-width: 768px) {
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-column {
    flex-direction: column !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
}
@media (min-width: 768px) {
  .flex-md-column {
    flex-direction: column !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 768px) {
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-column {
    flex-direction: column !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-column {
    flex-direction: column !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-column {
    flex-direction: column !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media (min-width: 576px) {
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 768px) {
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 768px) {
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media (min-width: 576px) {
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 768px) {
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 768px) {
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

@media (min-width: 576px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 768px) {
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 768px) {
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 768px) {
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 576px) {
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 576px) {
  .justify-content-sm-center {
    justify-content: center !important;
  }
}
@media (min-width: 576px) {
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 576px) {
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 576px) {
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 768px) {
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 768px) {
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 768px) {
  .justify-content-md-center {
    justify-content: center !important;
  }
}
@media (min-width: 768px) {
  .justify-content-md-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 768px) {
  .justify-content-md-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 768px) {
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-center {
    justify-content: center !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-xl-center {
    justify-content: center !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 1200px) {
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-center {
    justify-content: center !important;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
}
.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

@media (min-width: 576px) {
  .align-items-sm-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 576px) {
  .align-items-sm-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 576px) {
  .align-items-sm-center {
    align-items: center !important;
  }
}
@media (min-width: 576px) {
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 576px) {
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 768px) {
  .align-items-md-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 768px) {
  .align-items-md-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 768px) {
  .align-items-md-center {
    align-items: center !important;
  }
}
@media (min-width: 768px) {
  .align-items-md-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 768px) {
  .align-items-md-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 992px) {
  .align-items-lg-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 992px) {
  .align-items-lg-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 992px) {
  .align-items-lg-center {
    align-items: center !important;
  }
}
@media (min-width: 992px) {
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 992px) {
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 1200px) {
  .align-items-xl-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 1200px) {
  .align-items-xl-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 1200px) {
  .align-items-xl-center {
    align-items: center !important;
  }
}
@media (min-width: 1200px) {
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 1200px) {
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
}
@media (min-width: 1400px) {
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 1400px) {
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 1400px) {
  .align-items-xxl-center {
    align-items: center !important;
  }
}
@media (min-width: 1400px) {
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 1400px) {
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
}
.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

@media (min-width: 576px) {
  .align-content-sm-start {
    align-content: flex-start !important;
  }
}
@media (min-width: 576px) {
  .align-content-sm-end {
    align-content: flex-end !important;
  }
}
@media (min-width: 576px) {
  .align-content-sm-center {
    align-content: center !important;
  }
}
@media (min-width: 576px) {
  .align-content-sm-between {
    align-content: space-between !important;
  }
}
@media (min-width: 576px) {
  .align-content-sm-around {
    align-content: space-around !important;
  }
}
@media (min-width: 576px) {
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 768px) {
  .align-content-md-start {
    align-content: flex-start !important;
  }
}
@media (min-width: 768px) {
  .align-content-md-end {
    align-content: flex-end !important;
  }
}
@media (min-width: 768px) {
  .align-content-md-center {
    align-content: center !important;
  }
}
@media (min-width: 768px) {
  .align-content-md-between {
    align-content: space-between !important;
  }
}
@media (min-width: 768px) {
  .align-content-md-around {
    align-content: space-around !important;
  }
}
@media (min-width: 768px) {
  .align-content-md-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 992px) {
  .align-content-lg-start {
    align-content: flex-start !important;
  }
}
@media (min-width: 992px) {
  .align-content-lg-end {
    align-content: flex-end !important;
  }
}
@media (min-width: 992px) {
  .align-content-lg-center {
    align-content: center !important;
  }
}
@media (min-width: 992px) {
  .align-content-lg-between {
    align-content: space-between !important;
  }
}
@media (min-width: 992px) {
  .align-content-lg-around {
    align-content: space-around !important;
  }
}
@media (min-width: 992px) {
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 1200px) {
  .align-content-xl-start {
    align-content: flex-start !important;
  }
}
@media (min-width: 1200px) {
  .align-content-xl-end {
    align-content: flex-end !important;
  }
}
@media (min-width: 1200px) {
  .align-content-xl-center {
    align-content: center !important;
  }
}
@media (min-width: 1200px) {
  .align-content-xl-between {
    align-content: space-between !important;
  }
}
@media (min-width: 1200px) {
  .align-content-xl-around {
    align-content: space-around !important;
  }
}
@media (min-width: 1200px) {
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 1400px) {
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
}
@media (min-width: 1400px) {
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
}
@media (min-width: 1400px) {
  .align-content-xxl-center {
    align-content: center !important;
  }
}
@media (min-width: 1400px) {
  .align-content-xxl-between {
    align-content: space-between !important;
  }
}
@media (min-width: 1400px) {
  .align-content-xxl-around {
    align-content: space-around !important;
  }
}
@media (min-width: 1400px) {
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
}
.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .align-self-sm-auto {
    align-self: auto !important;
  }
}
@media (min-width: 576px) {
  .align-self-sm-start {
    align-self: flex-start !important;
  }
}
@media (min-width: 576px) {
  .align-self-sm-end {
    align-self: flex-end !important;
  }
}
@media (min-width: 576px) {
  .align-self-sm-center {
    align-self: center !important;
  }
}
@media (min-width: 576px) {
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 576px) {
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .align-self-md-auto {
    align-self: auto !important;
  }
}
@media (min-width: 768px) {
  .align-self-md-start {
    align-self: flex-start !important;
  }
}
@media (min-width: 768px) {
  .align-self-md-end {
    align-self: flex-end !important;
  }
}
@media (min-width: 768px) {
  .align-self-md-center {
    align-self: center !important;
  }
}
@media (min-width: 768px) {
  .align-self-md-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 768px) {
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .align-self-lg-auto {
    align-self: auto !important;
  }
}
@media (min-width: 992px) {
  .align-self-lg-start {
    align-self: flex-start !important;
  }
}
@media (min-width: 992px) {
  .align-self-lg-end {
    align-self: flex-end !important;
  }
}
@media (min-width: 992px) {
  .align-self-lg-center {
    align-self: center !important;
  }
}
@media (min-width: 992px) {
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 992px) {
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .align-self-xl-auto {
    align-self: auto !important;
  }
}
@media (min-width: 1200px) {
  .align-self-xl-start {
    align-self: flex-start !important;
  }
}
@media (min-width: 1200px) {
  .align-self-xl-end {
    align-self: flex-end !important;
  }
}
@media (min-width: 1200px) {
  .align-self-xl-center {
    align-self: center !important;
  }
}
@media (min-width: 1200px) {
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 1200px) {
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .align-self-xxl-auto {
    align-self: auto !important;
  }
}
@media (min-width: 1400px) {
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
}
@media (min-width: 1400px) {
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
}
@media (min-width: 1400px) {
  .align-self-xxl-center {
    align-self: center !important;
  }
}
@media (min-width: 1400px) {
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 1400px) {
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 576px) {
  .d-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
}
@media (min-width: 576px) {
  .d-sm-grid {
    display: grid !important;
  }
}
@media (min-width: 576px) {
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
}
@media (min-width: 576px) {
  .d-sm-table {
    display: table !important;
  }
}
@media (min-width: 576px) {
  .d-sm-table-row {
    display: table-row !important;
  }
}
@media (min-width: 576px) {
  .d-sm-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .d-md-grid {
    display: grid !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline-grid {
    display: inline-grid !important;
  }
}
@media (min-width: 768px) {
  .d-md-table {
    display: table !important;
  }
}
@media (min-width: 768px) {
  .d-md-table-row {
    display: table-row !important;
  }
}
@media (min-width: 768px) {
  .d-md-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-grid {
    display: grid !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
}
@media (min-width: 992px) {
  .d-lg-table {
    display: table !important;
  }
}
@media (min-width: 992px) {
  .d-lg-table-row {
    display: table-row !important;
  }
}
@media (min-width: 992px) {
  .d-lg-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-grid {
    display: grid !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-table {
    display: table !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-table-row {
    display: table-row !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-block {
    display: block !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-grid {
    display: grid !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-table {
    display: table !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-table-row {
    display: table-row !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-flex {
    display: flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
}
@media (min-width: 576px) {
  .float-sm-end {
    float: right !important;
  }
}
@media (min-width: 576px) {
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
}
@media (min-width: 768px) {
  .float-md-end {
    float: right !important;
  }
}
@media (min-width: 768px) {
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
}
@media (min-width: 992px) {
  .float-lg-end {
    float: right !important;
  }
}
@media (min-width: 992px) {
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-end {
    float: right !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-end {
    float: right !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-none {
    float: none !important;
  }
}
.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

@media (min-width: 576px) {
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
}
@media (min-width: 576px) {
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
}
@media (min-width: 576px) {
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
}
@media (min-width: 576px) {
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
}
@media (min-width: 576px) {
  .object-fit-sm-none {
    object-fit: none !important;
  }
}
@media (min-width: 768px) {
  .object-fit-md-contain {
    object-fit: contain !important;
  }
}
@media (min-width: 768px) {
  .object-fit-md-cover {
    object-fit: cover !important;
  }
}
@media (min-width: 768px) {
  .object-fit-md-fill {
    object-fit: fill !important;
  }
}
@media (min-width: 768px) {
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
}
@media (min-width: 768px) {
  .object-fit-md-none {
    object-fit: none !important;
  }
}
@media (min-width: 992px) {
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
}
@media (min-width: 992px) {
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
}
@media (min-width: 992px) {
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
}
@media (min-width: 992px) {
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
}
@media (min-width: 992px) {
  .object-fit-lg-none {
    object-fit: none !important;
  }
}
@media (min-width: 1200px) {
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
}
@media (min-width: 1200px) {
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
}
@media (min-width: 1200px) {
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
}
@media (min-width: 1200px) {
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
}
@media (min-width: 1200px) {
  .object-fit-xl-none {
    object-fit: none !important;
  }
}
@media (min-width: 1400px) {
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
}
@media (min-width: 1400px) {
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
}
@media (min-width: 1400px) {
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
}
@media (min-width: 1400px) {
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
}
@media (min-width: 1400px) {
  .object-fit-xxl-none {
    object-fit: none !important;
  }
}
.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle- {
  translate-middle: translate(-50%, -50%) !important;
}

.translate-middle-x {
  translate-middle: translateX(-50%) !important;
}

.translate-middle-y {
  translate-middle: translateY(-50%) !important;
}

.border-0 {
  border-width: 0px !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-start {
    text-align: left !important;
  }
}
@media (min-width: 576px) {
  .text-sm-end {
    text-align: right !important;
  }
}
@media (min-width: 576px) {
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-start {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  .text-md-end {
    text-align: right !important;
  }
}
@media (min-width: 768px) {
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-start {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  .text-lg-end {
    text-align: right !important;
  }
}
@media (min-width: 992px) {
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-start {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-end {
    text-align: right !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-start {
    text-align: left !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-end {
    text-align: right !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-opacity-25 {
  text-opacity: 0.25 !important;
}

.text-opacity-50 {
  text-opacity: 0.5 !important;
}

.text-opacity-75 {
  text-opacity: 0.75 !important;
}

.text-opacity-100 {
  text-opacity: 1 !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.px-p-0 {
  padding: 0px !important;
}

.px-p-1 {
  padding: 1px !important;
}

.px-p-2 {
  padding: 2px !important;
}

.px-p-3 {
  padding: 3px !important;
}

.px-p-4 {
  padding: 4px !important;
}

.px-p-5 {
  padding: 5px !important;
}

.px-p-6 {
  padding: 6px !important;
}

.px-p-7 {
  padding: 7px !important;
}

.px-p-8 {
  padding: 8px !important;
}

.px-p-9 {
  padding: 9px !important;
}

.px-p-10 {
  padding: 10px !important;
}

.px-p-11 {
  padding: 11px !important;
}

.px-p-12 {
  padding: 12px !important;
}

.px-p-13 {
  padding: 13px !important;
}

.px-p-14 {
  padding: 14px !important;
}

.px-p-15 {
  padding: 15px !important;
}

.px-p-16 {
  padding: 16px !important;
}

.px-p-17 {
  padding: 17px !important;
}

.px-p-18 {
  padding: 18px !important;
}

.px-p-19 {
  padding: 19px !important;
}

.px-p-20 {
  padding: 20px !important;
}

.px-p-21 {
  padding: 21px !important;
}

.px-p-22 {
  padding: 22px !important;
}

.px-p-23 {
  padding: 23px !important;
}

.px-p-24 {
  padding: 24px !important;
}

.px-p-25 {
  padding: 25px !important;
}

.px-pt-0 {
  padding-top: 0px !important;
}

.px-pt-1 {
  padding-top: 1px !important;
}

.px-pt-2 {
  padding-top: 2px !important;
}

.px-pt-3 {
  padding-top: 3px !important;
}

.px-pt-4 {
  padding-top: 4px !important;
}

.px-pt-5 {
  padding-top: 5px !important;
}

.px-pt-6 {
  padding-top: 6px !important;
}

.px-pt-7 {
  padding-top: 7px !important;
}

.px-pt-8 {
  padding-top: 8px !important;
}

.px-pt-9 {
  padding-top: 9px !important;
}

.px-pt-10 {
  padding-top: 10px !important;
}

.px-pt-11 {
  padding-top: 11px !important;
}

.px-pt-12 {
  padding-top: 12px !important;
}

.px-pt-13 {
  padding-top: 13px !important;
}

.px-pt-14 {
  padding-top: 14px !important;
}

.px-pt-15 {
  padding-top: 15px !important;
}

.px-pt-16 {
  padding-top: 16px !important;
}

.px-pt-17 {
  padding-top: 17px !important;
}

.px-pt-18 {
  padding-top: 18px !important;
}

.px-pt-19 {
  padding-top: 19px !important;
}

.px-pt-20 {
  padding-top: 20px !important;
}

.px-pt-21 {
  padding-top: 21px !important;
}

.px-pt-22 {
  padding-top: 22px !important;
}

.px-pt-23 {
  padding-top: 23px !important;
}

.px-pt-24 {
  padding-top: 24px !important;
}

.px-pt-25 {
  padding-top: 25px !important;
}

.px-pe-0 {
  padding-right: 0px !important;
}

.px-pe-1 {
  padding-right: 1px !important;
}

.px-pe-2 {
  padding-right: 2px !important;
}

.px-pe-3 {
  padding-right: 3px !important;
}

.px-pe-4 {
  padding-right: 4px !important;
}

.px-pe-5 {
  padding-right: 5px !important;
}

.px-pe-6 {
  padding-right: 6px !important;
}

.px-pe-7 {
  padding-right: 7px !important;
}

.px-pe-8 {
  padding-right: 8px !important;
}

.px-pe-9 {
  padding-right: 9px !important;
}

.px-pe-10 {
  padding-right: 10px !important;
}

.px-pe-11 {
  padding-right: 11px !important;
}

.px-pe-12 {
  padding-right: 12px !important;
}

.px-pe-13 {
  padding-right: 13px !important;
}

.px-pe-14 {
  padding-right: 14px !important;
}

.px-pe-15 {
  padding-right: 15px !important;
}

.px-pe-16 {
  padding-right: 16px !important;
}

.px-pe-17 {
  padding-right: 17px !important;
}

.px-pe-18 {
  padding-right: 18px !important;
}

.px-pe-19 {
  padding-right: 19px !important;
}

.px-pe-20 {
  padding-right: 20px !important;
}

.px-pe-21 {
  padding-right: 21px !important;
}

.px-pe-22 {
  padding-right: 22px !important;
}

.px-pe-23 {
  padding-right: 23px !important;
}

.px-pe-24 {
  padding-right: 24px !important;
}

.px-pe-25 {
  padding-right: 25px !important;
}

.px-pb-0 {
  padding-bottom: 0px !important;
}

.px-pb-1 {
  padding-bottom: 1px !important;
}

.px-pb-2 {
  padding-bottom: 2px !important;
}

.px-pb-3 {
  padding-bottom: 3px !important;
}

.px-pb-4 {
  padding-bottom: 4px !important;
}

.px-pb-5 {
  padding-bottom: 5px !important;
}

.px-pb-6 {
  padding-bottom: 6px !important;
}

.px-pb-7 {
  padding-bottom: 7px !important;
}

.px-pb-8 {
  padding-bottom: 8px !important;
}

.px-pb-9 {
  padding-bottom: 9px !important;
}

.px-pb-10 {
  padding-bottom: 10px !important;
}

.px-pb-11 {
  padding-bottom: 11px !important;
}

.px-pb-12 {
  padding-bottom: 12px !important;
}

.px-pb-13 {
  padding-bottom: 13px !important;
}

.px-pb-14 {
  padding-bottom: 14px !important;
}

.px-pb-15 {
  padding-bottom: 15px !important;
}

.px-pb-16 {
  padding-bottom: 16px !important;
}

.px-pb-17 {
  padding-bottom: 17px !important;
}

.px-pb-18 {
  padding-bottom: 18px !important;
}

.px-pb-19 {
  padding-bottom: 19px !important;
}

.px-pb-20 {
  padding-bottom: 20px !important;
}

.px-pb-21 {
  padding-bottom: 21px !important;
}

.px-pb-22 {
  padding-bottom: 22px !important;
}

.px-pb-23 {
  padding-bottom: 23px !important;
}

.px-pb-24 {
  padding-bottom: 24px !important;
}

.px-pb-25 {
  padding-bottom: 25px !important;
}

.px-ps-0 {
  padding-left: 0px !important;
}

.px-ps-1 {
  padding-left: 1px !important;
}

.px-ps-2 {
  padding-left: 2px !important;
}

.px-ps-3 {
  padding-left: 3px !important;
}

.px-ps-4 {
  padding-left: 4px !important;
}

.px-ps-5 {
  padding-left: 5px !important;
}

.px-ps-6 {
  padding-left: 6px !important;
}

.px-ps-7 {
  padding-left: 7px !important;
}

.px-ps-8 {
  padding-left: 8px !important;
}

.px-ps-9 {
  padding-left: 9px !important;
}

.px-ps-10 {
  padding-left: 10px !important;
}

.px-ps-11 {
  padding-left: 11px !important;
}

.px-ps-12 {
  padding-left: 12px !important;
}

.px-ps-13 {
  padding-left: 13px !important;
}

.px-ps-14 {
  padding-left: 14px !important;
}

.px-ps-15 {
  padding-left: 15px !important;
}

.px-ps-16 {
  padding-left: 16px !important;
}

.px-ps-17 {
  padding-left: 17px !important;
}

.px-ps-18 {
  padding-left: 18px !important;
}

.px-ps-19 {
  padding-left: 19px !important;
}

.px-ps-20 {
  padding-left: 20px !important;
}

.px-ps-21 {
  padding-left: 21px !important;
}

.px-ps-22 {
  padding-left: 22px !important;
}

.px-ps-23 {
  padding-left: 23px !important;
}

.px-ps-24 {
  padding-left: 24px !important;
}

.px-ps-25 {
  padding-left: 25px !important;
}

.px-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-px-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.px-px-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.px-px-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.px-px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-px-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.px-px-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.px-px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-px-9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.px-px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-px-11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.px-px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-px-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.px-px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.px-px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-px-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.px-px-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.px-px-19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.px-px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-px-21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.px-px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.px-px-23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.px-px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-py-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.px-py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.px-py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.px-py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.px-py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.px-py-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.px-py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-py-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.px-py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-py-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.px-py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.px-py-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.px-py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.px-py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.px-py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.px-py-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.px-py-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.px-py-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.px-py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-py-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.px-py-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.px-py-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.px-py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.px-py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.px-m-0 {
  margin: 0px !important;
}

.px-m-1 {
  margin: 1px !important;
}

.px-m-2 {
  margin: 2px !important;
}

.px-m-3 {
  margin: 3px !important;
}

.px-m-4 {
  margin: 4px !important;
}

.px-m-5 {
  margin: 5px !important;
}

.px-m-6 {
  margin: 6px !important;
}

.px-m-7 {
  margin: 7px !important;
}

.px-m-8 {
  margin: 8px !important;
}

.px-m-9 {
  margin: 9px !important;
}

.px-m-10 {
  margin: 10px !important;
}

.px-m-11 {
  margin: 11px !important;
}

.px-m-12 {
  margin: 12px !important;
}

.px-m-13 {
  margin: 13px !important;
}

.px-m-14 {
  margin: 14px !important;
}

.px-m-15 {
  margin: 15px !important;
}

.px-m-16 {
  margin: 16px !important;
}

.px-m-17 {
  margin: 17px !important;
}

.px-m-18 {
  margin: 18px !important;
}

.px-m-19 {
  margin: 19px !important;
}

.px-m-20 {
  margin: 20px !important;
}

.px-m-21 {
  margin: 21px !important;
}

.px-m-22 {
  margin: 22px !important;
}

.px-m-23 {
  margin: 23px !important;
}

.px-m-24 {
  margin: 24px !important;
}

.px-m-25 {
  margin: 25px !important;
}

.px-mt-0 {
  margin-top: 0px !important;
}

.px-mt-1 {
  margin-top: 1px !important;
}

.px-mt-2 {
  margin-top: 2px !important;
}

.px-mt-3 {
  margin-top: 3px !important;
}

.px-mt-4 {
  margin-top: 4px !important;
}

.px-mt-5 {
  margin-top: 5px !important;
}

.px-mt-6 {
  margin-top: 6px !important;
}

.px-mt-7 {
  margin-top: 7px !important;
}

.px-mt-8 {
  margin-top: 8px !important;
}

.px-mt-9 {
  margin-top: 9px !important;
}

.px-mt-10 {
  margin-top: 10px !important;
}

.px-mt-11 {
  margin-top: 11px !important;
}

.px-mt-12 {
  margin-top: 12px !important;
}

.px-mt-13 {
  margin-top: 13px !important;
}

.px-mt-14 {
  margin-top: 14px !important;
}

.px-mt-15 {
  margin-top: 15px !important;
}

.px-mt-16 {
  margin-top: 16px !important;
}

.px-mt-17 {
  margin-top: 17px !important;
}

.px-mt-18 {
  margin-top: 18px !important;
}

.px-mt-19 {
  margin-top: 19px !important;
}

.px-mt-20 {
  margin-top: 20px !important;
}

.px-mt-21 {
  margin-top: 21px !important;
}

.px-mt-22 {
  margin-top: 22px !important;
}

.px-mt-23 {
  margin-top: 23px !important;
}

.px-mt-24 {
  margin-top: 24px !important;
}

.px-mt-25 {
  margin-top: 25px !important;
}

.px-me-0 {
  margin-right: 0px !important;
}

.px-me-1 {
  margin-right: 1px !important;
}

.px-me-2 {
  margin-right: 2px !important;
}

.px-me-3 {
  margin-right: 3px !important;
}

.px-me-4 {
  margin-right: 4px !important;
}

.px-me-5 {
  margin-right: 5px !important;
}

.px-me-6 {
  margin-right: 6px !important;
}

.px-me-7 {
  margin-right: 7px !important;
}

.px-me-8 {
  margin-right: 8px !important;
}

.px-me-9 {
  margin-right: 9px !important;
}

.px-me-10 {
  margin-right: 10px !important;
}

.px-me-11 {
  margin-right: 11px !important;
}

.px-me-12 {
  margin-right: 12px !important;
}

.px-me-13 {
  margin-right: 13px !important;
}

.px-me-14 {
  margin-right: 14px !important;
}

.px-me-15 {
  margin-right: 15px !important;
}

.px-me-16 {
  margin-right: 16px !important;
}

.px-me-17 {
  margin-right: 17px !important;
}

.px-me-18 {
  margin-right: 18px !important;
}

.px-me-19 {
  margin-right: 19px !important;
}

.px-me-20 {
  margin-right: 20px !important;
}

.px-me-21 {
  margin-right: 21px !important;
}

.px-me-22 {
  margin-right: 22px !important;
}

.px-me-23 {
  margin-right: 23px !important;
}

.px-me-24 {
  margin-right: 24px !important;
}

.px-me-25 {
  margin-right: 25px !important;
}

.px-mb-0 {
  margin-bottom: 0px !important;
}

.px-mb-1 {
  margin-bottom: 1px !important;
}

.px-mb-2 {
  margin-bottom: 2px !important;
}

.px-mb-3 {
  margin-bottom: 3px !important;
}

.px-mb-4 {
  margin-bottom: 4px !important;
}

.px-mb-5 {
  margin-bottom: 5px !important;
}

.px-mb-6 {
  margin-bottom: 6px !important;
}

.px-mb-7 {
  margin-bottom: 7px !important;
}

.px-mb-8 {
  margin-bottom: 8px !important;
}

.px-mb-9 {
  margin-bottom: 9px !important;
}

.px-mb-10 {
  margin-bottom: 10px !important;
}

.px-mb-11 {
  margin-bottom: 11px !important;
}

.px-mb-12 {
  margin-bottom: 12px !important;
}

.px-mb-13 {
  margin-bottom: 13px !important;
}

.px-mb-14 {
  margin-bottom: 14px !important;
}

.px-mb-15 {
  margin-bottom: 15px !important;
}

.px-mb-16 {
  margin-bottom: 16px !important;
}

.px-mb-17 {
  margin-bottom: 17px !important;
}

.px-mb-18 {
  margin-bottom: 18px !important;
}

.px-mb-19 {
  margin-bottom: 19px !important;
}

.px-mb-20 {
  margin-bottom: 20px !important;
}

.px-mb-21 {
  margin-bottom: 21px !important;
}

.px-mb-22 {
  margin-bottom: 22px !important;
}

.px-mb-23 {
  margin-bottom: 23px !important;
}

.px-mb-24 {
  margin-bottom: 24px !important;
}

.px-mb-25 {
  margin-bottom: 25px !important;
}

.px-ms-0 {
  margin-left: 0px !important;
}

.px-ms-1 {
  margin-left: 1px !important;
}

.px-ms-2 {
  margin-left: 2px !important;
}

.px-ms-3 {
  margin-left: 3px !important;
}

.px-ms-4 {
  margin-left: 4px !important;
}

.px-ms-5 {
  margin-left: 5px !important;
}

.px-ms-6 {
  margin-left: 6px !important;
}

.px-ms-7 {
  margin-left: 7px !important;
}

.px-ms-8 {
  margin-left: 8px !important;
}

.px-ms-9 {
  margin-left: 9px !important;
}

.px-ms-10 {
  margin-left: 10px !important;
}

.px-ms-11 {
  margin-left: 11px !important;
}

.px-ms-12 {
  margin-left: 12px !important;
}

.px-ms-13 {
  margin-left: 13px !important;
}

.px-ms-14 {
  margin-left: 14px !important;
}

.px-ms-15 {
  margin-left: 15px !important;
}

.px-ms-16 {
  margin-left: 16px !important;
}

.px-ms-17 {
  margin-left: 17px !important;
}

.px-ms-18 {
  margin-left: 18px !important;
}

.px-ms-19 {
  margin-left: 19px !important;
}

.px-ms-20 {
  margin-left: 20px !important;
}

.px-ms-21 {
  margin-left: 21px !important;
}

.px-ms-22 {
  margin-left: 22px !important;
}

.px-ms-23 {
  margin-left: 23px !important;
}

.px-ms-24 {
  margin-left: 24px !important;
}

.px-ms-25 {
  margin-left: 25px !important;
}

.px-mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.px-mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.px-mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.px-mx-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.px-mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.px-mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.px-mx-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.px-mx-7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.px-mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.px-mx-9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.px-mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.px-mx-11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.px-mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.px-mx-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.px-mx-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.px-mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.px-mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.px-mx-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.px-mx-18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.px-mx-19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.px-mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.px-mx-21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.px-mx-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.px-mx-23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}

.px-mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.px-mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.px-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.px-my-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.px-my-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.px-my-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.px-my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.px-my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.px-my-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.px-my-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.px-my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.px-my-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.px-my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.px-my-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.px-my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.px-my-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.px-my-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.px-my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.px-my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.px-my-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.px-my-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.px-my-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.px-my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.px-my-21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.px-my-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.px-my-23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.px-my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.px-my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.btn, .btn-round-icon-borderless, .btn-lavender, .btn-light, .btn-square-icon, .btn-secondary, .btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 100%;
  margin: 10px 8px;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  word-wrap: break-word;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn i, .btn-round-icon-borderless i, .btn-lavender i, .btn-light i, .btn-square-icon i, .btn-secondary i, .btn-primary i {
  font-size: 1.5em;
}

.btn:disabled, .btn-round-icon-borderless:disabled, .btn-lavender:disabled, .btn-light:disabled, .btn-square-icon:disabled, .btn-secondary:disabled, .btn-primary:disabled, .btn[disabled=true], [disabled=true].btn-round-icon-borderless, [disabled=true].btn-lavender, [disabled=true].btn-light, [disabled=true].btn-square-icon, [disabled=true].btn-secondary, [disabled=true].btn-primary {
  opacity: 0.5;
  pointer-events: none;
}

.btn-primary {
  background-color: var(--primary-color-500);
  color: var(--white);
  border: 1px solid var(--primary-color-200);
}

.btn-primary:hover, .btn-primary[aria-expanded=true] {
  background-color: var(--primary-color-400);
}

.btn-primary:focus {
  background-color: var(--primary-color-400);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-color-500), 0.25);
  outline: none;
}

.btn-primary:active {
  background-color: var(--primary-color-600);
  border-color: var(--primary-color-600);
  box-shadow: none;
}

.btn-secondary {
  background-color: var(--primary-color-50);
  color: var(--primary-color-700);
  border: 1px solid var(--primary-color-200);
}

.btn-secondary:hover, .btn-secondary[aria-expanded=true] {
  background-color: var(--gray-color-100);
}

.btn-secondary:focus {
  background-color: var(--gray-color-100);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-color-50), 0.25);
  outline: none;
}

.btn-secondary:active {
  background-color: var(--primary-color-200);
  border-color: var(--primary-color-200);
  box-shadow: none;
}

.btn-light, .btn-square-icon {
  background-color: var(--white);
  color: var(--gray-color-700);
  border: 1px solid var(--gray-color-300);
}

.btn-light:hover, .btn-square-icon:hover, .btn-light[aria-expanded=true], [aria-expanded=true].btn-square-icon {
  background-color: var(--gray-color-100);
}

.btn-light:focus, .btn-square-icon:focus {
  background-color: var(--gray-color-100);
  box-shadow: 0 0 0 0.2rem rgba(var(--white), 0.25);
  outline: none;
}

.btn-light:active, .btn-square-icon:active {
  background-color: var(--gray-color-200);
  border-color: var(--gray-color-200);
  box-shadow: none;
}

.btn-lavender {
  background-color: var(--pale-lavender-color-50);
  color: var(--pale-lavender-button-text-color);
  border: 1px solid var(--gray-color-300);
}

.btn-lavender:hover, .btn-lavender[aria-expanded=true] {
  background-color: var(--pale-lavender-color-200);
}

.btn-lavender:focus {
  background-color: var(--pale-lavender-color-200);
  box-shadow: 0 0 0 0.2rem rgba(var(--white), 0.25);
  outline: none;
}

.btn-lavender:active {
  background-color: var(--pale-lavender-color-300);
  border-color: var(--pale-lavender-color-300);
  box-shadow: none;
}

.btn-square-icon {
  padding: 8px;
}

.btn-square-icon span {
  display: none;
}

.btn-square-icon span.mat-badge-active {
  display: inline-block;
}

.btn-round-icon-borderless {
  color: var(--mat-icon-color);
  font-size: 16px;
  padding: 4px;
  margin: 0;
  box-shadow: none;
  border: none;
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn-round-icon-borderless i {
  font-size: 1em;
}

.btn-round-icon-borderless span {
  display: none;
}

.btn-round-icon-borderless span.mat-badge-active {
  display: inline-block;
}

.btn-round-icon-borderless:hover:not(:disabled), .btn-round-icon-borderless[aria-expanded=true] {
  background-color: var(--gray-color-300);
  border-radius: 50%;
}

.btn-round-icon-borderless:focus {
  background-color: var(--gray-color-300);
  border-radius: 50%;
}

.btn-round-icon-borderless:active {
  background-color: var(--gray-color-400);
  border-radius: 50%;
}

.tuula-menu {
  background-color: var(--primary-color-500) !important;
  border: solid;
  border-radius: var(--border-radius) !important;
  border-width: 1px;
  border-color: var(--dark-border);
}

.tuula-menu * {
  box-sizing: border-box;
}

.tuula-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-bottom: solid 1px var(--primary-color-400) !important;
  cursor: pointer;
}

.tuula-menu-item i {
  display: inline-block;
  filter: invert(1);
}

.tuula-menu-item:hover:not(:disabled) {
  background-color: var(--primary-color-400) !important;
}

.tuula-menu-item:disabled, .tuula-menu-item[disabled=true] {
  opacity: 0.5;
  pointer-events: none;
}

.tuula-menu-item .tuula-menu-item-text {
  display: inline-block;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  white-space: normal;
  line-height: var(--mat-menu-item-label-text-line-height);
  letter-spacing: var(--mat-menu-item-label-text-tracking);
  font-family: var(--tuula-font-family);
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
  display: inline-block;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --black: #000;
  --white: #fff;
  --primary-color-25: #E6E5F2;
  --primary-color-50: #DEDDE9;
  --primary-color-100: #B7B6C5;
  --primary-color-200: #9291A8;
  --primary-color-300: #6E6D8B;
  --primary-color-400: #4A496F;
  --primary-color-500: #262353;
  --primary-color-600: #201E44;
  --primary-color-700: #191836;
  --primary-color-800: #131228;
  --primary-color-900: #0D0C1C;
  --primary-color-950: #080711;
  --error-color-25: #FFFBFA;
  --error-color-50: #FEF3F2;
  --error-color-100: #FEE4E2;
  --error-color-200: #FECDCA;
  --error-color-300: #FDA29B;
  --error-color-400: #F97066;
  --error-color-500: #F04438;
  --error-color-600: #D92D20;
  --error-color-700: #B42318;
  --error-color-800: #912018;
  --error-color-900: #7A271A;
  --error-color-950: #55160C;
  --warning-color-25: #FFFCF5;
  --warning-color-50: #FFFAEB;
  --warning-color-100: #FEF0C7;
  --warning-color-200: #FEDF89;
  --warning-color-300: #FEC84B;
  --warning-color-400: #FDB022;
  --warning-color-500: #F79009;
  --warning-color-600: #DC6803;
  --warning-color-700: #B54708;
  --warning-color-800: #93370D;
  --warning-color-900: #7A2E0E;
  --warning-color-950: #4E1D09;
  --success-color-25: #F6FEF9;
  --success-color-50: #ECFDF3;
  --success-color-100: #DCFAE6;
  --success-color-200: #ABEFC6;
  --success-color-300: #75E0A7;
  --success-color-400: #47CD89;
  --success-color-500: #17B26A;
  --success-color-600: #079455;
  --success-color-700: #067647;
  --success-color-800: #085D3A;
  --success-color-900: #074D31;
  --success-color-950: #053321;
  --gray-color-25: #FCFCFD;
  --gray-color-50: #F9FAFB;
  --gray-color-100: #F2F4F7;
  --gray-color-200: #EAECF0;
  --gray-color-300: #D0D5DD;
  --gray-color-400: #98A2B3;
  --gray-color-500: #667085;
  --gray-color-600: #475467;
  --gray-color-700: #344054;
  --gray-color-800: #1D2939;
  --gray-color-900: #101828;
  --gray-color-950: #0C111D;
  --tomato-color-25: #FFEDE9;
  --tomato-color-50: #FEE0DB;
  --tomato-color-100: #FDCBC2;
  --tomato-color-200: #FCB1A3;
  --tomato-color-300: #FC9885;
  --tomato-color-400: #FB7E67;
  --tomato-color-500: #FA654B;
  --tomato-color-600: #D1543F;
  --tomato-color-700: #A74431;
  --tomato-color-800: #7D3225;
  --tomato-color-900: #532119;
  --tomato-color-950: #32140F;
  --winter-wizard-color-25: #F1F9FF;
  --winter-wizard-color-50: #ECF7FF;
  --winter-wizard-color-100: #DFF2FE;
  --winter-wizard-color-200: #CFEBFE;
  --winter-wizard-color-300: #C0E4FE;
  --winter-wizard-color-400: #B0DEFD;
  --winter-wizard-color-500: #A0D7FD;
  --winter-wizard-color-600: #85B3D3;
  --winter-wizard-color-700: #6B8FA9;
  --winter-wizard-color-800: #506B7E;
  --winter-wizard-color-900: #354854;
  --winter-wizard-color-950: #202B33;
  --powder-blue-color-25: #FAFFFD;
  --powder-blue-color-50: #F1FBF8;
  --powder-blue-color-100: #E8F9F4;
  --powder-blue-color-200: #DDF5EE;
  --powder-blue-color-300: #D2F2E8;
  --powder-blue-color-400: #C6EFE3;
  --powder-blue-color-500: #BBECDD;
  --powder-blue-color-600: #9CC5B8;
  --powder-blue-color-700: #7D9D93;
  --powder-blue-color-800: #5D766E;
  --powder-blue-color-900: #3E4F4A;
  --powder-blue-color-950: #252F2C;
  --pale-lavender-color-25: #FBF9FF;
  --pale-lavender-color-50: #F8F3FF;
  --pale-lavender-color-100: #F3EBFE;
  --pale-lavender-color-200: #EDE1FE;
  --pale-lavender-color-300: #E7D8FE;
  --pale-lavender-color-400: #E1CEFD;
  --pale-lavender-color-500: #DBC4FD;
  --pale-lavender-color-600: #B6A3D3;
  --pale-lavender-color-700: #9283A9;
  --pale-lavender-color-800: #6D627E;
  --pale-lavender-color-900: #494154;
  --pale-lavender-color-950: #2C2733;
  --tuula-font-family: "Onest";
  --sidebar-line-color: #262353;
  --sidebar-font-color: #fff;
  --sidebar-bg-color: #262353;
  --form-bg-color: #ffffff;
  --form-line-color: #efefef;
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --pale-lavender-button-text-color: #6941c6;
  --form-nd-input-height:39px;
  --form-nd-label-text-color:var(--gray-color-700);
  --form-nd-label-font-size:14px;
  --form-nd-label-font-weight:500;
  --form-nd-label-line-height:20px;
  --form-nd-inner-text-color:var(--gray-color-700);
  --form-nd-inner-text-font-size:14px;
  --form-nd-inner-text-font-weight:400;
  --form-nd-inner-text-line-height:20px;
  --form-nd-error-font-size:14px;
  --form-nd-error-font-weight:400;
  --form-nd-error-line-height:20px;
  --form-nd-hint-text-color:var(--gray-color-600);
  --form-nd-hint-font-size:14px;
  --form-nd-hint-font-weight:400;
  --form-nd-hint-line-height:20px;
  --border-radius: 4px;
}

* {
  font-family: var(--tuula-font-family);
}

mat-form-field {
  display: block !important;
}

.mat-mdc-select-panel-above div.mat-mdc-select-panel.pagination-panel-class {
  background-color: var(--white);
}

.mat-mdc-select-panel-above div.mat-mdc-select-panel.pagination-panel-class .mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-select-panel-above div.mat-mdc-select-panel.pagination-panel-class .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: var(--primary-color-500) !important;
  color: var(--white) !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #424242;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #424242;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #424242;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #424242;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #424242;
  --mdc-radio-selected-hover-icon-color: #424242;
  --mdc-radio-selected-icon-color: #424242;
  --mdc-radio-selected-pressed-icon-color: #424242;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #424242;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(66, 66, 66, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #424242;
  --mat-text-button-state-layer-color: #424242;
  --mat-text-button-ripple-color: rgba(66, 66, 66, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
  --mat-text-button-state-layer-color: #ff4081;
  --mat-text-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #424242;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #424242;
  --mat-outlined-button-ripple-color: rgba(66, 66, 66, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ff4081;
  --mat-outlined-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #424242;
  --mdc-checkbox-selected-hover-icon-color: #424242;
  --mdc-checkbox-selected-icon-color: #424242;
  --mdc-checkbox-selected-pressed-icon-color: #424242;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #424242;
  --mdc-checkbox-selected-hover-state-layer-color: #424242;
  --mdc-checkbox-selected-pressed-state-layer-color: #424242;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #757575;
  --mdc-switch-selected-handle-color: #757575;
  --mdc-switch-selected-hover-state-layer-color: #757575;
  --mdc-switch-selected-pressed-state-layer-color: #757575;
  --mdc-switch-selected-focus-handle-color: #212121;
  --mdc-switch-selected-hover-handle-color: #212121;
  --mdc-switch-selected-pressed-handle-color: #212121;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-track-color: #e0e0e0;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #424242;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #424242;
  --mat-tab-header-active-ripple-color: #424242;
  --mat-tab-header-inactive-ripple-color: #424242;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #424242;
  --mat-tab-header-active-hover-label-text-color: #424242;
  --mat-tab-header-active-focus-indicator-color: #424242;
  --mat-tab-header-active-hover-indicator-color: #424242;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #424242;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #424242;
  --mdc-slider-focus-handle-color: #424242;
  --mdc-slider-hover-handle-color: #424242;
  --mdc-slider-active-track-color: #424242;
  --mdc-slider-inactive-track-color: #424242;
  --mdc-slider-with-tick-marks-inactive-container-color: #424242;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #424242;
  --mat-slider-hover-state-layer-color: rgba(66, 66, 66, 0.05);
  --mat-slider-focus-state-layer-color: rgba(66, 66, 66, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #ff4081;
  --mat-slider-hover-state-layer-color: rgba(255, 64, 129, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 64, 129, 0.2);
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
:root {
  --black: #000;
  --white: #fff;
  --primary-color-25: #E6E5F2;
  --primary-color-50: #DEDDE9;
  --primary-color-100: #B7B6C5;
  --primary-color-200: #9291A8;
  --primary-color-300: #6E6D8B;
  --primary-color-400: #4A496F;
  --primary-color-500: #262353;
  --primary-color-600: #201E44;
  --primary-color-700: #191836;
  --primary-color-800: #131228;
  --primary-color-900: #0D0C1C;
  --primary-color-950: #080711;
  --error-color-25: #FFFBFA;
  --error-color-50: #FEF3F2;
  --error-color-100: #FEE4E2;
  --error-color-200: #FECDCA;
  --error-color-300: #FDA29B;
  --error-color-400: #F97066;
  --error-color-500: #F04438;
  --error-color-600: #D92D20;
  --error-color-700: #B42318;
  --error-color-800: #912018;
  --error-color-900: #7A271A;
  --error-color-950: #55160C;
  --warning-color-25: #FFFCF5;
  --warning-color-50: #FFFAEB;
  --warning-color-100: #FEF0C7;
  --warning-color-200: #FEDF89;
  --warning-color-300: #FEC84B;
  --warning-color-400: #FDB022;
  --warning-color-500: #F79009;
  --warning-color-600: #DC6803;
  --warning-color-700: #B54708;
  --warning-color-800: #93370D;
  --warning-color-900: #7A2E0E;
  --warning-color-950: #4E1D09;
  --success-color-25: #F6FEF9;
  --success-color-50: #ECFDF3;
  --success-color-100: #DCFAE6;
  --success-color-200: #ABEFC6;
  --success-color-300: #75E0A7;
  --success-color-400: #47CD89;
  --success-color-500: #17B26A;
  --success-color-600: #079455;
  --success-color-700: #067647;
  --success-color-800: #085D3A;
  --success-color-900: #074D31;
  --success-color-950: #053321;
  --gray-color-25: #FCFCFD;
  --gray-color-50: #F9FAFB;
  --gray-color-100: #F2F4F7;
  --gray-color-200: #EAECF0;
  --gray-color-300: #D0D5DD;
  --gray-color-400: #98A2B3;
  --gray-color-500: #667085;
  --gray-color-600: #475467;
  --gray-color-700: #344054;
  --gray-color-800: #1D2939;
  --gray-color-900: #101828;
  --gray-color-950: #0C111D;
  --tomato-color-25: #FFEDE9;
  --tomato-color-50: #FEE0DB;
  --tomato-color-100: #FDCBC2;
  --tomato-color-200: #FCB1A3;
  --tomato-color-300: #FC9885;
  --tomato-color-400: #FB7E67;
  --tomato-color-500: #FA654B;
  --tomato-color-600: #D1543F;
  --tomato-color-700: #A74431;
  --tomato-color-800: #7D3225;
  --tomato-color-900: #532119;
  --tomato-color-950: #32140F;
  --winter-wizard-color-25: #F1F9FF;
  --winter-wizard-color-50: #ECF7FF;
  --winter-wizard-color-100: #DFF2FE;
  --winter-wizard-color-200: #CFEBFE;
  --winter-wizard-color-300: #C0E4FE;
  --winter-wizard-color-400: #B0DEFD;
  --winter-wizard-color-500: #A0D7FD;
  --winter-wizard-color-600: #85B3D3;
  --winter-wizard-color-700: #6B8FA9;
  --winter-wizard-color-800: #506B7E;
  --winter-wizard-color-900: #354854;
  --winter-wizard-color-950: #202B33;
  --powder-blue-color-25: #FAFFFD;
  --powder-blue-color-50: #F1FBF8;
  --powder-blue-color-100: #E8F9F4;
  --powder-blue-color-200: #DDF5EE;
  --powder-blue-color-300: #D2F2E8;
  --powder-blue-color-400: #C6EFE3;
  --powder-blue-color-500: #BBECDD;
  --powder-blue-color-600: #9CC5B8;
  --powder-blue-color-700: #7D9D93;
  --powder-blue-color-800: #5D766E;
  --powder-blue-color-900: #3E4F4A;
  --powder-blue-color-950: #252F2C;
  --pale-lavender-color-25: #FBF9FF;
  --pale-lavender-color-50: #F8F3FF;
  --pale-lavender-color-100: #F3EBFE;
  --pale-lavender-color-200: #EDE1FE;
  --pale-lavender-color-300: #E7D8FE;
  --pale-lavender-color-400: #E1CEFD;
  --pale-lavender-color-500: #DBC4FD;
  --pale-lavender-color-600: #B6A3D3;
  --pale-lavender-color-700: #9283A9;
  --pale-lavender-color-800: #6D627E;
  --pale-lavender-color-900: #494154;
  --pale-lavender-color-950: #2C2733;
  --tuula-font-family: "Onest";
  --sidebar-line-color: #262353;
  --sidebar-font-color: #fff;
  --sidebar-bg-color: #262353;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

:root {
  --form-title-bg-color: #f3f4f7;
  --form-title-font-color: #303030;
  --form-title-line-color: #e4e4e4;
  --card-bg-color: #f3f4f7;
  --card-line-color: #e4e4e4;
  --card-shade-bg-color: #f5f6f9;
  --card-shade-bg-color2: #f7f8fb;
  --card-shade-font-color: #272727;
  --card-shade-line-color: #e4e4e4;
  --table-header-bg-color: #fbfcfd;
  --table-header-font-color: #404040;
  --form-bg-color: #ffffff;
  --form-font-color: #606060;
  --form-line-color: #efefef;
  --form-shade-bg-color: #f9fafc;
  --form-dynamic-bg-color: #fcfcfc;
  --master-bg-color: #f9fafc;
  --master-font-color: #272727;
  --master-line-color: #eaeaea;
  --top-bg-color: #ffffff;
  --top-font-color: #272727;
  --dark-border: #767676;
  --light-border: #f7f8fb;
  --warning-border: #e03e0d;
  --navbar-collapsed-width: 77px;
  --navbar-expanded-width: 230px;
  --navbar-mobile-breakpoint: 992px;
  --form-nd-input-height:39px;
  --form-nd-label-text-color:var(--gray-color-700);
  --form-nd-label-font-size:14px;
  --form-nd-label-font-weight:500;
  --form-nd-label-line-height:20px;
  --form-nd-inner-text-color:var(--gray-color-700);
  --form-nd-inner-text-font-size:14px;
  --form-nd-inner-text-font-weight:400;
  --form-nd-inner-text-line-height:20px;
  --form-nd-error-font-size:14px;
  --form-nd-error-font-weight:400;
  --form-nd-error-line-height:20px;
  --form-nd-hint-text-color:var(--gray-color-600);
  --form-nd-hint-font-size:14px;
  --form-nd-hint-font-weight:400;
  --form-nd-hint-line-height:20px;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: var(--tuula-font-family);
}

.toast-title {
  font-weight: bold;
  font-size: 14px;
  padding-left: 5px;
}

.toast-message {
  word-wrap: break-word;
  font-size: 14px;
  padding-left: 5px;
}

*::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

*::-webkit-scrollbar-track {
  background-color: var(--white);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray-color-300);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-color-400);
}

.c4p-color-container {
  color: var(--master-font-color) !important;
}

.c4p-flex {
  display: flex;
}

.c4p-content {
  padding-top: 10px;
}

.c4p-content-menu {
  float: right;
}

.c4p-tab-content {
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  height: calc(100% - 95px);
}

.c4p-tab-menu {
  position: absolute;
  float: right;
  overflow: hidden;
  right: 30px;
  top: -40px;
  z-index: 10;
}

.c4p-horizontal-divider {
  padding: 10px;
}

.c4p-vertical-divider {
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.c4p-underline {
  border: solid;
  border-width: 0px 0px 1px 0px;
  margin-bottom: 20px;
  padding-bottom: 6px;
  border-color: var(--master-line-color);
  width: 100%;
  display: block;
}

.c4p-form {
  background-color: var(--form-title-bg-color);
  color: var(--primary-color-700);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  margin: 5px 0px 5px 0px;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
}

app-panel.white-background > div.c4p-form {
  background-color: white !important;
}

.c4p-popup-form, .c4p-popup-form-share-and-sign, .c4p-popup-form-small, .c4p-popup-form-medium, .c4p-popup-form-large {
  background-color: var(--form-title-bg-color);
  color: var(--primary-color-700);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  margin: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90vh;
  max-width: 95vw;
}

.side-sheet-form {
  background-color: var(--form-title-bg-color);
  color: var(--primary-color-700);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: Ubuntu;
  margin: 0px;
  overflow-y: auto;
  max-height: 90vh;
  max-width: 95vw;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100vh;
}

.cdk-overlay-pane .invoice-detail {
  max-height: 90vh;
  overflow: scroll;
}

.c4p-popup-form-large {
  max-height: 90vh;
  max-width: 220vh;
  min-width: 180vh;
}

.c4p-popup-form-medium {
  max-width: 110vh;
  max-height: 90vh;
}

.c4p-popup-form-small {
  max-width: 80vh;
  max-height: 90vh;
}

.c4p-popup-form-share-and-sign {
  max-height: 90vh;
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.c4p-form-title {
  border-bottom: 1px solid var(--gray-color-200);
  background-color: var(--white);
  height: 30px;
  position: relative;
  padding: 8px 0px 8px 0px;
}

.c4p-form-row {
  height: 100%;
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--form-bg-color);
  align-items: center;
}

.c4p-form-row-borderless {
  height: 100%;
  border: none;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--form-bg-color);
  align-items: center;
}

.c4p-form-row-no-height {
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--form-bg-color);
  align-items: center;
}

.checklist-form-row {
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--form-bg-color);
  align-items: center;
}

.form-row {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.c4p-dynamic-form-row {
  border: solid;
  border-color: var(--light-border);
  border-width: 4px 4px 4px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--form-bg-color);
  align-items: center;
}

.c4p-dynamic-form-row-group {
  border: solid;
  border-color: var(--light-border);
  border-width: 4px 4px 4px 0px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--form-dynamic-bg-color);
  align-items: center;
}

.c4p-form-row-center {
  height: 100%;
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--form-bg-color);
  align-items: center;
}

.c4p-form-row-right {
  height: 100%;
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: var(--form-bg-color);
  align-items: center;
  padding-right: 15px;
}

.c4p-form-row-center-borderless {
  height: 100%;
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--form-bg-color);
  align-items: center;
}

.c4p-form-input {
  min-height: 75px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}

.c4p-form-input-small {
  min-height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}

.c4p-form-input-nowidth {
  height: 30px;
  margin-left: 8px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.c4p-form-input-small-nowidth {
  height: auto;
  margin-left: 8px;
  vertical-align: middle;
  display: flex;
  align-items: flex-end;
}

.c4p-form-box {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background-color: grey;
  border-radius: 4px;
  color: white;
  justify-content: center;
}

.c4p-form-box-red {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background-color: red;
  border-radius: 4px;
  color: white;
  justify-content: center;
}

.c4p-form-input-big {
  min-height: 100px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
}

.c4p-form-input-full-width {
  min-height: 75px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.c4p-form-input-full-width-no-height {
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: var(--form-font-color);
}

.c4p-dynamic-form-input-small {
  min-height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.c4p-dynamic-form-input-normal {
  min-height: 35px;
  margin-left: 20px;
  margin-right: 10px;
  vertical-align: middle;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c4p-form-label {
  font-family: var(--tuula-font-family);
  font-size: 12px;
  color: var(--form-font-color);
}

.c4p-form-text-area {
  height: 100%;
  width: 100% !important;
  background-color: var(--form-bg-color) !important;
  color: var(--form-font-color) !important;
  border: solid !important;
  border-width: 0px 0px 1px 0px !important;
  border-radius: 4px;
  border-color: var(--form-line-color) !important;
}

.c4p-form-text-area-gate-keeper {
  height: 100%;
  width: 100% !important;
  background-color: var(--form-shade-bg-color) !important;
  color: var(--form-font-color) !important;
  border: solid !important;
  border-width: 1px !important;
  border-color: var(--form-line-color) !important;
}

.c4p-align-left {
  display: flex;
  align-items: center;
}

.c4p-align-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.c4p-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  word-wrap: normal;
}

.c4p-form-questionset-stepper {
  height: 100%;
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: inline;
  background: none;
  align-items: top;
}
.c4p-form-questionset-stepper .mat-step-header .mat-step-icon-selected {
  background-color: var(--primary-color-500);
}

.c4p-question-set-input-scale {
  --mdc-slider-handle-color: var(--primary-color-600);
  --mdc-slider-focus-handle-color: var(--primary-color-600);
  --mdc-slider-hover-handle-color: var(--primary-color-600);
  --mdc-slider-active-track-color: var(--primary-color-600);
  --mdc-slider-label-container-color: var(--primary-color-600);
  --mat-slider-value-indicator-width: 15px !important;
  --mat-slider-value-indicator-height: 15px !important;
  --mat-slider-value-indicator-padding: 2px !important;
  --mat-slider-value-indicator-opacity: 0.5;
  --mat-slider-ripple-color: rgba(255,255,255,0.5) !important;
}

.c4p-form-comment-footer {
  padding: 5px 10px 0px 0px;
  justify-content: center !important;
  text-align: right;
  background-color: var(--card-shade-bg-color);
}

.c4p-form-comment-confirmation {
  justify-content: center !important;
  text-align: right;
  margin-top: 10px;
}

.c4p-form-comment-panel {
  background-color: var(--form-bg-color);
  color: var(--form-font-color);
  border-width: 1px;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
}

.c4p-form-comment-heading {
  background-color: var(--form-bg-color);
  color: var(--form-font-color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  text-align: center;
  padding: 20px 10px 10px 10px;
}

.c4p-form-comments-container-right {
  justify-content: left !important;
  text-align: left;
  border: solid;
  border-width: 1px 0px 1px 0px;
  border-color: var(--card-shade-line-color);
  background-color: transparent;
  padding: 16px 8px;
  word-break: break-all;
  width: calc(100% - 140px);
}

.c4p-form-comments-container {
  overflow-y: scroll;
  height: calc(80vh - 200px);
}

.c4p-form-comments {
  background-color: var(--form-bg-color);
  color: var(--form-font-color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  text-align: center;
  padding: 20px 10px 10px 10px;
}

.mat-divider {
  border-top-color: var(--primary-color-500);
}

.c4p-form-questionset {
  height: 100%;
  border: solid;
  border-color: var(--light-border);
  border-width: 0px 0px 1px 0px;
  display: flex;
  background: none;
}

.c4p-form-questions {
  background-color: var(--form-bg-color);
  color: var(--form-font-color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
}

.c4p-form-questions-tab {
  background-color: var(--form-bg-color);
  color: var(--form-font-color);
  border-width: 1px;
  border-color: var(--form-bg-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  margin: 5px 5px 5px 5px;
  box-shadow: 0px 0px 4px rgba(60, 60, 60, 0.4);
  width: 100%;
  overflow: hidden;
  min-height: 30vh;
}

.c4p-headerless-tabgroup .mat-mdc-tab-header {
  display: none !important;
}

.c4p-question-label {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  margin-right: 8px;
  color: var(--primary-color-700);
}

.c4p-form-row-questions-no-border {
  height: 100%;
  border-width: 0px 0px 0px 0px;
  align-items: center;
}

.c4p-form-row-questions {
  height: 100%;
  border: solid;
  border-color: var(--form-title-bg-color);
  border-width: 0px 0px 0px 10px;
  background-color: var(--form-bg-color);
  align-items: center;
  margin: 8px 4px;
  padding: 1px 8px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.c4p-form-row-questions:first-child {
  word-break: break-all;
}

.c4p-form-questions-title {
  border: solid;
  border-color: var(--form-title-line-color);
  border-width: 0px 0px 1px 0px;
  height: 45px;
  position: relative;
  padding: 0px 20px 0px 5px;
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--form-title-bg-color);
  color: var(--primary-color-700);
  font-size: 13px;
  align-items: center;
}

.c4p-form-question-answers-container {
  overflow-y: scroll;
  max-height: 550px;
  height: auto;
  min-height: 30vh;
}

.c4p-form-questionset mat-table {
  max-height: 550px;
  overflow-y: auto;
}

.c4p-form-question-answers {
  margin: 20px 0px;
}

.c4p-question-set-input {
  min-height: 35px;
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0.9;
}

.c4p-question-set-input-small {
  vertical-align: middle;
  min-width: 100%;
  display: flex;
  align-items: center;
  border: solid;
  border-width: 0px 0px 1px 0px;
  border-color: var(--form-dynamic-bg-color);
  text-shadow: 0 0 black;
  padding: 4px 0px;
}

.c4p-question-set-input-big {
  min-height: 100px;
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
}

.c4p-hint-icons {
  font-size: 15px;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-hint-icons:hover {
  opacity: 1;
  color: var(--primary-color-500);
}

.c4p-mismatch-answer-row {
  align-items: baseline;
  padding: 0 16px;
  border-bottom: none;
}

.c4p-action-icons {
  font-size: 16px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-action-icons-external {
  font-size: 18px;
  opacity: 0.9;
  flex-direction: row !important;
  align-items: baseline !important;
}

.c4p-action-icons:hover {
  opacity: 1;
  color: var(--primary-color-500);
}

.c4p-action-badge {
  font-size: 20px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-action-badge:hover {
  opacity: 1;
  color: var(--primary-color-500);
}

.mat-badge-content {
  font-size: 10px !important;
  width: auto !important;
  height: 15px !important;
  line-height: 15px !important;
  padding: 0 4px !important;
  white-space: nowrap;
  border-radius: 4px;
  transform: scale(0.8);
}

.mat-badge-small.mat-badge-overlap .mat-badge-content {
  margin-bottom: -10px !important;
  margin-left: -9px !important;
  margin-top: var(--mat-badge-small-size-container-overlap-offset) px !important;
  margin-right: var(--mat-badge-small-size-container-overlap-offset) px !important;
}

.mat-badge {
  --mat-badge-background-color: var(--gray-color-100);
  --mat-badge-text-color: var(--gray-color-700);
}

.mat-badge-warn {
  --mat-badge-background-color: var(--error-color-100);
  --mat-badge-text-color: var(--error-color-700);
}

.c4p-action-menu-icon {
  font-size: 16px;
  margin-top: 6px;
  margin-right: 0px !important;
  cursor: pointer;
  color: var(--white) !important;
}

.c4p-top-icons {
  font-size: 20px;
  padding-top: 8px;
  cursor: pointer;
  color: var(--top-font-color);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-top-icons:hover {
  color: var(--primary-color-500);
}

.c4p-back-icons {
  background-color: var(--primary-color-500);
  color: var(--white);
  border-style: solid;
  border-color: var(--primary-color-800);
  border-width: 1px;
  border-radius: 3px;
  padding-top: 0px;
  margin-top: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-right: 8px;
}

.c4p-back-icons:hover {
  background-color: var(--primary-color-800);
}

.c4p-input-undo-icon {
  font-size: 13px !important;
  position: absolute;
  top: -10px;
  right: 2px;
  z-index: 1;
  opacity: 0.2;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-input-undo-icon:hover {
  opacity: 1;
  color: var(--primary-color-500);
}

.mat-mdc-select-panel {
  background-color: var(--form-bg-color) !important;
  color: var(--form-font-color);
  border-color: var(--dark-border);
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-value {
  color: var(--form-font-color);
}

.mat-mdc-select {
  font-size: 12px !important;
  font-family: var(--tuula-font-family) !important;
  color: var(--form-font-color);
}

.mat-mdc-option {
  font-size: 12px !important;
  color: var(--form-font-color);
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  line-height: 16px !important;
  min-height: 32px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: var(--form-font-color) !important;
  font-family: var(--tuula-font-family);
}

.mat-mdc-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
  background-color: var(--primary-color-500) !important;
  color: var(--white) !important;
}

.mat-mdc-option.mdc-list-item {
  justify-content: center;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: var(--primary-color-500) !important;
  color: var(--white) !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) .mdc-list-item__primary-text {
  color: var(--white) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--form-font-color) !important;
}

.mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--form-font-color) !important;
}

.mat-select-search-input {
  background-color: #fff !important;
}

.mat-select-search-inner .mat-divider {
  border-top-color: var(--form-line-color);
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color-600) !important;
  --mdc-switch-selected-handle-color: var(--white) !important;
  --mdc-switch-selected-focus-handle-color: var(--white) !important;
  --mdc-switch-selected-hover-handle-color: var(--white) !important;
  --mdc-switch-selected-pressed-handle-color: var(--white) !important;
  --mdc-switch-unselected-handle-color: var(--white) !important;
  --mdc-switch-unselected-focus-handle-color: var(--white) !important;
  --mdc-switch-unselected-hover-handle-color: var(--white) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--white) !important;
  --mdc-switch-selected-focus-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-hover-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary-color-600) !important;
  --mdc-switch-selected-track-color: var(--primary-color-600) !important;
  --mdc-switch-unselected-focus-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-hover-track-color: var(--gray-color-100)!important;
  --mdc-switch-unselected-pressed-track-color: var(--gray-color-100) !important;
  --mdc-switch-unselected-track-color: var(--gray-color-100) !important;
  --mdc-switch-selected-icon-color: var(--white) !important; /*tick color*/
  --mdc-switch-unselected-icon-color: var(--gray-color-100) !important; /*minus color*/
  --mat-switch-track-outline-color: var(--gray-color-300) !important;
  --mat-switch-disabled-unselected-track-outline-color: var(--gray-color-200) !important;
  --mat-switch-label-text-color: var(--gray-color-900) !important;
  --mdc-switch-track-width: 40px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-shape: 9999px;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mdc-switch-state-layer-size: 24px;
}

mat-expansion-panel {
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--tuula-font-family);
}

.mat-expansion-panel-body {
  padding: 0 0px 0px !important;
  font-family: var(--tuula-font-family);
  background: linear-gradient(to right, var(--primary-color-500), var(--primary-color-500)) !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 24px !important;
}

.mat-expansion-panel-header.mat-expanded {
  background: linear-gradient(to right, var(--primary-color-500), var(--primary-color-500)) !important;
}

.mat-mdc-radio-button .mdc-label {
  font-family: var(--tuula-font-family) !important;
  font-size: 12px !important;
  color: var(--form-font-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-outer-circle {
  height: 14px !important;
  width: 14px !important;
  top: 3px !important;
  left: 3px !important;
  border-width: 1px !important;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--sidebar-bg-color);
  --mdc-radio-selected-hover-icon-color: var(--sidebar-bg-color);
  --mdc-radio-selected-icon-color: var(--sidebar-bg-color);
  --mdc-radio-selected-pressed-icon-color: var(--sidebar-bg-color);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--sidebar-bg-color);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container {
  width: 15px !important;
}

.mat-mdc-form-field {
  width: 100% !important;
}

.mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
  min-height: 46px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
  padding-right: 0;
  padding-left: 0;
}

.mat-mdc-floating-label {
  line-height: 1.15rem !important;
}

.mat-mdc-input-element {
  font-family: var(--tuula-font-family) !important;
  font-size: 12px !important;
  color: var(--form-font-color) !important;
}

.mat-mdc-input-element:disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8) !important;
  -webkit-opacity: 0.8 !important;
  opacity: 0.8 !important;
}

input.mat-mdc-input-element {
  color: var(--form-font-color);
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  width: 100%;
  max-width: 100%;
  font-family: var(--tuula-font-family);
  font-size: 12px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
input::placeholder,
.mat-mdc-form-field-text-suffix,
.mat-mdc-form-field-text-prefix,
.mat-mdc-floating-label {
  color: var(--form-font-color) !important;
  font-size: 12px !important;
  opacity: 0.6;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mdc-line-ripple {
  background-color: var(--form-line-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mdc-line-ripple {
  background-color: var(--form-line-color);
}

.mdc-text-field--filled .mdc-line-ripple::before {
  border-bottom-width: 1px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--form-line-color) !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--warning-border) !important;
}

.mdc-line-ripple--activate {
  background-color: var(--form-line-color);
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::after {
  border: 1px solid;
  border-style: dotted;
  border-color: var(--form-line-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-mdc-floating-label {
  top: 20px;
  font-size: 12px !important;
}

.mat-mdc-form-field-error {
  color: var(--warning-border) !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-error-custom {
  color: var(--warning-border) !important;
  font-size: 75%;
  position: absolute;
  margin-top: 45px;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-mdc-floating-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-mdc-floating-label .mat-form-field-required-marker {
  color: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-required-marker {
  color: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.mat-datepicker-toggle,
.mat-mdc-select-arrow,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--gray-color-700);
  opacity: 0.65;
}

mat-datepicker-toggle .mat-mdc-icon-button svg {
  width: 12px !important;
  height: 12px !important;
  color: #8c8d8d;
  position: absolute !important;
  bottom: 20% !important;
  right: 20% !important;
}

.mat-datepicker-content .mat-calendar,
.mat-datepicker-content .time-container {
  font-size: 12px !important;
  font-family: var(--tuula-font-family) !important;
  color: var(--gray-color-700);
  background-color: var(--form-bg-color);
  border-color: var(--form-line-color);
}

.mat-datepicker-actions {
  background-color: var(--form-bg-color);
}

.mat-datepicker-actions .mat-mdc-outlined-button:not(:disabled) {
  color: var(--gray-color-700);
}

.mat-calendar-body {
  font-size: 12px !important;
}

.mat-calendar-body-cell-content {
  color: var(--gray-color-700) !important;
  border-color: transparent !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-color-500) !important;
  color: var(--white) !important;
  border-color: var(--primary-color-500) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: $checkbox-disabled-checked-checkmark-color !important;
  --mdc-checkbox-selected-icon-color: $checkbox-checked-bg-color !important;
  border: none;
  padding-bottom: 6px;
  border-width: 0px 0px 1px 0px;
  opacity: 1;
}
.mat-mdc-checkbox .mdc-label {
  font-family: var(--tuula-font-family);
  font-size: 12px;
  color: var(--form-font-color);
  white-space: break-spaces;
  word-break: break-word;
}

.mat-pseudo-checkbox,
.mdc-checkbox__background,
.mdc-checkbox {
  border-width: 1px !important;
  border-radius: 4px !important;
  border-color: var(--gray-color-300) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  background-color: var(--primary-color-50) !important;
  border-color: var(--primary-color-600) !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background {
  background-color: var(--gray-color-100) !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background-color: var(--gray-color-100) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: var(--primary-color-600) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: var(--gray-color-300) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
textarea.mat-mdc-input-element {
  padding: 0;
  margin: 0;
}

.mat-button-toggle-appearance-standard .mat-pseudo-checkbox {
  width: auto !important;
  height: auto !important;
  border: none !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content, .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  border: none !important;
  margin: 0 !important;
}

.mat-mdc-tab {
  border: solid !important;
  border-width: 0px 0px 2px 0px !important;
  border-color: var(--primary-color-500) !important;
  opacity: 0.6 !important;
  margin: 0px 1px 0px 1px !important;
}

.mdc-tab--active {
  opacity: 1 !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--primary-color-500) !important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 4px;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color-500);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-link {
  font-family: var(--tuula-font-family);
  font-size: 13px;
  font-weight: 400;
  color: var(--top-font-color);
  min-width: 0px !important;
  height: 40px !important;
  padding: 0px 8px 0px 8px !important;
  margin: 0px 1px 0px 1px !important;
  border: solid !important;
  border-width: 0px 0px 4px 0px !important;
  border-color: var(--primary-color-500) !important;
  opacity: 0.6 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mdc-tab--active {
  opacity: 1 !important;
}

.mdc-tab-indicator__content--underline {
  border: none !important;
}

.mat-mdc-tab-labels {
  display: flex;
  width: 25%;
  flex: 1 0 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-labels:last-child.mat-tab-label {
  min-width: 0;
  padding: 0 12px;
  color: unset;
  opacity: unset;
  right: 0px;
}

.mat-mdc-tab-header {
  border-bottom-color: var(--master-line-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  height: auto !important;
  overflow: hidden !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group[mat-stretch-tabs] > .mat-mdc-tab-header .mat-tab-label {
  flex-basis: auto !important;
  flex-grow: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-disabled .mat-mdc-select-value {
  color: var(--form-font-color);
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: var(--primary-color-500);
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-background {
  fill: var(--primary-color-500);
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mdc-linear-progress__buffer {
  background-color: var(--primary-color-500);
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-fill::after {
  background-color: var(--master-line-color);
}

.c4p-progress-bar {
  background-color: var(--top-bg-color);
  height: 1px !important;
}

.c4p-column-filter {
  height: 20px;
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 0px 0px 0px 4px;
  background-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.05);
  margin: 5px 0px;
}

.mat-mdc-icon-button:disabled {
  opacity: var(--mdc-icon-button-disabled-icon-opacity) !important;
}

.c4p-table-view-wrap {
  background-color: var(--form-title-bg-color);
  color: var(--primary-color-700) !important;
  border-width: 0px;
  border-style: solid;
  border-color: var(--form-title-line-color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  min-width: 315px;
  /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
}
.c4p-table-view-wrap .c4p-table-title-bar {
  display: flex;
  flex: wrap;
  flex-direction: row;
  flex-basis: auto;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-color-200);
  background-color: var(--white);
  height: 45px;
}
.c4p-table-view-wrap .mat-mdc-table {
  overflow-x: auto;
  font-family: var(--tuula-font-family) !important;
}
.c4p-table-view-wrap .mat-mdc-header-cell {
  background-color: var(--table-header-bg-color);
  color: var(--table-header-font-color);
  font-size: 12px;
  font-weight: 500;
}
.c4p-table-view-wrap .mat-mdc-cell {
  color: var(--form-font-color);
  font-family: var(--tuula-font-family);
  font-size: 12px;
  font-weight: 400;
}
.c4p-table-view-wrap .mat-sort-header {
  color: var(--table-header-font-color);
  padding-top: 5px;
}
.c4p-table-view-wrap .mat-mdc-row,
.c4p-table-view-wrap mat-header-row,
.c4p-table-view-wrap mat-footer-row {
  border-color: var(--form-line-color) !important;
}
.c4p-table-view-wrap .mat-mdc-header-row {
  min-height: 35px;
  border-color: var(--form-line-color);
  background-color: var(--table-header-bg-color);
}
.c4p-table-view-wrap .mat-mdc-row {
  background-color: var(--form-bg-color) !important;
  border-color: var(--light-border) !important;
  color: var(--form-font-color) !important;
}
.c4p-table-view-wrap .mat-mdc-row:hover {
  background-color: var(--light-border) !important;
}
.c4p-table-view-wrap .mat-mdc-row.highlighted {
  background-color: var(--form-title-bg-color) !important;
  color: var(--white) !important;
  border-left-width: 5px !important;
  border-left-color: var(--primary-color-500) !important;
  border-bottom-color: var(--form-title-line-color) !important;
}
.c4p-table-view-wrap .mat-mdc-row.highlighted-non-selection {
  background-color: var(--form-title-bg-color) !important;
  color: var(--white) !important;
  border-bottom-color: var(--form-title-line-color) !important;
}
.c4p-table-view-wrap .mdc-data-table__cell,
.c4p-table-view-wrap .mdc-data-table__header-cell,
.c4p-table-view-wrap .mdc-data-table__footer-cell {
  padding: 0;
}
.c4p-table-view-wrap .mdc-data-table__cell,
.c4p-table-view-wrap .mdc-data-table__header-cell,
.c4p-table-view-wrap .mdc-data-table__footer-cell {
  box-sizing: content-box;
}
.c4p-table-view-wrap .mat-mdc-cell:first-of-type,
.c4p-table-view-wrap .mat-mdc-header-cell:first-of-type,
.c4p-table-view-wrap .mat-mdc-footer-cell:first-of-type {
  padding-left: 24px;
}
.c4p-table-view-wrap .mat-mdc-cell:last-of-type,
.c4p-table-view-wrap .mat-mdc-header-cell:last-of-type,
.c4p-table-view-wrap .last-mdc-footer-cell:last-of-type {
  padding-right: 24px;
}
.c4p-table-view-wrap .mat-mdc-paginator {
  color: var(--primary-color-700);
  background-color: transparent;
  font-family: var(--tuula-font-family);
}
.c4p-table-view-wrap .mat-mdc-paginator-icon {
  width: 20px !important;
}
.c4p-table-view-wrap .mat-mdc-paginator-page-size-select {
  color: var(--primary-color-700) !important;
  width: 55px !important;
}
.c4p-table-view-wrap .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px !important;
}
.c4p-table-view-wrap .mat-mdc-paginator,
.c4p-table-view-wrap .mat-mdc-paginator-page-size .mat-mdc-select-trigger,
.c4p-table-view-wrap .mat-mdc-paginator-page-size .mat-mdc-select-value,
.c4p-table-view-wrap .mat-mdc-paginator-page-size .mat-mdc-select-arrow {
  color: var(--primary-color-700) !important;
  font-size: 11px;
}
.c4p-table-view-wrap .mat-mdc-paginator-range-label {
  margin: 0px 10px 0px 10px;
}
.c4p-table-view-wrap .mat-mdc-button.mat-primary[disabled],
.c4p-table-view-wrap .mat-button.mat-accent[disabled],
.c4p-table-view-wrap .mat-button.mat-warn[disabled],
.c4p-table-view-wrap .mat-button[disabled][disabled],
.c4p-table-view-wrap .mat-mdc-icon-button.mat-primary[disabled],
.c4p-table-view-wrap .mat-icon-button.mat-accent[disabled],
.c4p-table-view-wrap .mat-icon-button.mat-warn[disabled],
.c4p-table-view-wrap .mat-icon-button[disabled][disabled],
.c4p-table-view-wrap .mat-mdc-outlined-button.mat-primary[disabled],
.c4p-table-view-wrap .mat-stroked-button.mat-accent[disabled],
.c4p-table-view-wrap .mat-stroked-button.mat-warn[disabled],
.c4p-table-view-wrap .mat-stroked-button[disabled][disabled] {
  color: var(--primary-color-700);
  font-size: 12px;
  opacity: 0.6;
}
.c4p-table-view-wrap .mat-mdc-paginator-outer-container {
  height: auto;
  align-items: center;
}
.c4p-table-view-wrap .mat-sort-header-arrow {
  color: var(--table-header-font-color);
}
.c4p-table-view-wrap .mat-sort-header-button {
  color: var(--table-header-font-color);
  font-family: var(--tuula-font-family);
}
.c4p-table-view-wrap .mat-column-menu_action {
  flex: 0 0 30px;
}
.c4p-table-view-wrap table.mat-mdc-table {
  display: table;
}

.mat-mdc-paginator-container {
  min-height: auto;
  height: auto;
}

.mat-mdc-paginator-range-actions {
  height: inherit;
}

.mat-mdc-paginator-page-size {
  align-items: center;
  height: inherit;
}

.cp4-collaboration-avatar {
  background-color: white;
  border: solid;
  border-radius: 50%;
  border-width: 1px;
  z-index: 0;
  margin-left: -10px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
}

.cp4-collaboration-avatar:hover {
  z-index: 10;
  cursor: pointer;
}

.cp4-collaboration-avatar-active {
  border-color: var(--top-bg-color);
}

.cp4-collaboration-avatar-inactive {
  border-color: var(--top-bg-color);
}

.c4p-menu {
  background-color: var(--primary-color-500) !important;
  border: solid;
  border-radius: 4px !important;
  border-width: 1px;
  border-color: var(--dark-border);
}

.c4p-menu-item {
  border-color: var(--primary-color-500) !important;
  border: solid !important;
  border-width: 0px 0px 1px 0px !important;
}

.c4p-menu-item[disabled=true] > span.c4p-menu-title,
div.disabled-inline-action {
  opacity: 0.5;
  pointer-events: none;
}

.c4p-menu-title {
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
}

.mat-mdc-menu-item {
  border-color: var(--primary-color-500) !important;
  color: var(--white);
  font-family: var(--tuula-font-family);
  padding: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-bottom: solid 1px var(--primary-color-400) !important;
}

.mat-mdc-menu-item .mat-icon-no-color {
  color: var(--white);
}

.mat-icon {
  outline: none;
  height: 24px !important;
  width: 24px !important;
}

.mat-mdc-menu-content {
  padding: 0px !important;
}

.mat-mdc-menu-panel {
  min-height: 0px !important;
}

.lang-menu {
  margin-top: 5px !important;
}

.c4p-status-icon {
  font-size: 12px;
  font-weight: 500;
  min-width: 60px;
  text-align: center;
  padding: 0 6px;
  mix-blend-mode: multiply;
  border-radius: 16px;
  background-color: var(--primary-color-500);
  color: var(--white);
}

.c4p-status-icon-final, .c4p-status-icon-done,
.c4p-status-icon-confirmed, .c4p-status-icon-processed, .c4p-status-icon-signed,
.c4p-status-icon-open, .c4p-status-icon-completed, .c4p-status-icon-invoiced,
.c4p-status-icon-concept_invoice, .c4p-status-icon-approved,
.c4p-status-icon-unchanged, .c4p-status-icon-ready, .c4p-status-icon-accepted {
  background-color: var(--success-color-50);
  color: var(--success-color-700);
  border: 1px solid var(--success-color-200);
}

.c4p-status-icon-active, .c4p-status-icon-proposed, .c4p-status-icon-partially_accepted,
.c4p-status-icon-assigned, .c4p-status-icon-archived {
  background-color: var(--winter-wizard-color-50);
  color: var(--winter-wizard-color-700);
  border: 1px solid var(--winter-wizard-color-200);
}

.c4p-status-icon-declined, .c4p-status-icon-processing {
  background-color: var(--tomato-color-50);
  color: var(--tomato-color-700);
  border: 1px solid var(--tomato-color-200);
}

.c4p-status-icon-inactive {
  background-color: var(--gray-color-50);
  color: var(--gray-color-700);
  border: 1px solid var(--gray-color-200);
}

.c4p-status-icon-draft, .c4p-status-icon-pending, .c4p-status-icon-zpm-draft,
.c4p-status-icon-deleted, .c4p-status-icon-rejected {
  background-color: var(--warning-color-50);
  color: var(--warning-color-700);
  border: 1px solid var(--warning-color-200);
}

.c4p-status-icon-closed, .c4p-status-icon-dropped_out, .c4p-status-icon-failed,
.c4p-status-icon-locked, .c4p-status-icon-invalid, .c4p-status-icon-titlechanged,
.c4p-status-icon-typechanged, .c4p-status-icon-error {
  background-color: var(--error-color-50);
  color: var(--error-color-700);
  border: 1px solid var(--error-color-200);
}

.c4p-status-icon-unknown, .c4p-status-icon-cancelled, .c4p-status-icon-submitted,
.c4p-status-icon-correction {
  background-color: var(--pale-lavender-color-50);
  color: var(--pale-lavender-color-700);
  border: 1px solid var(--pale-lavender-color-200);
}

.c4p-progress-back {
  background: solid;
  background-color: var(--error-color-50);
  margin-right: 2px;
  border: 1px solid var(--error-color-200);
  color: var(--error-color-700);
  border-radius: 2px;
  text-align: center !important;
  vertical-align: middle;
  width: 75px;
  height: 22px;
  position: relative;
}

.c4p-progress-front {
  background: solid;
  background-color: var(--success-color-200);
  border: 1px solid var(--success-color-200);
  color: var(--success-color-700);
  border-radius: 2px;
  height: 22px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.c4p-indicator-icon {
  padding-top: 8px;
  font-size: 16px;
}

.c4p-indicator-icon-true {
  color: #0eaf26;
}

.c4p-indicator-icon-false {
  color: #db820f;
}

.mat-mdc-tooltip {
  color: white;
  background-color: #5c5c5c;
  font-size: 9px;
  font-family: var(--tuula-font-family);
}

.c4p-search {
  display: inline-flex;
  text-align: left;
  max-width: 150px;
  font-size: 12px;
  background-color: var(--form-bg-color);
  color: var(--master-font-color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--form-title-line-color);
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
  width: 130px;
}

.c4p-search-icon {
  font-size: 18px;
  margin-top: 6px;
  margin-left: 4px;
  cursor: pointer;
  color: var(--form-font-color);
}

.c4p-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  flex-basis: auto;
  background-color: var(--form-bg-color);
}

.c4p-grid-item {
  min-width: 280px;
  margin: 8px 4px 8px 0px;
  border: solid;
  border-width: 1px 1px 1px 1px !important;
  border-color: var(--form-bg-color);
}

.c4p-grid-note {
  width: 265px;
  margin: 8px 4px 8px 0px;
  border: solid;
  border-width: 1px 1px 1px 1px !important;
  border-color: var(--form-bg-color);
}

.c4p-panel-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  flex-basis: auto;
  background-color: var(--form-bg-color);
}

.c4p-panel-grid-item {
  min-width: 320px;
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}

.c4p-card-view {
  border-radius: 4px !important;
  border-width: 1px 1px 1px 1px !important;
  border: solid;
  border-color: var(--card-line-color);
  background-color: var(--card-shade-bg-color2);
  font-family: var(--tuula-font-family);
  font-size: 12px;
  margin: 4px;
  padding: 0px !important;
  box-shadow: 0px 0px 0px rgba(60, 60, 60, 0.4) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-card-view:hover {
  box-shadow: 0px 0px 6px rgba(60, 60, 60, 0.3) !important;
}

.c4p-card-view-left {
  justify-content: center !important;
  text-align: center;
  border: solid;
  border-width: 1px 1px 1px 0px;
  border-color: var(--card-shade-line-color);
  background-color: var(--card-bg-color);
  width: 110px;
  padding: 8px;
}

.c4p-card-view-title {
  color: var(--primary-color-700);
  height: 8px;
}

.c4p-card-view-right {
  justify-content: left !important;
  text-align: left;
  border: solid;
  border-width: 1px 0px 1px 0px;
  border-color: var(--card-shade-line-color);
  background-color: var(--card-shade-bg-color);
  width: 160px;
  padding: 16px 8px;
}

.c4p-card-view-label {
  margin: 8px 0px 4px 0px;
}

.c4p-card-view-value {
  margin: 4px 0px 12px 0px;
}

.c4p-card-view-title {
  margin: 0px 5px;
  justify-content: space-between;
}

.notes-title {
  font-weight: bolder !important;
  height: 15px;
}

.c4p-card-view-value-list {
  margin: 6px 0px 12px 0px;
}

.c4p-card-view-list-item {
  padding: 2px 1px 2px 1px;
  margin: 0px 2px;
  border-radius: 4px;
  border-width: 1px;
  border-color: var(--card-shade-line-color);
  color: var(--form-font-color);
  text-align: center;
  font-size: 12px;
  height: auto;
}

.c4p-card-view-list-item:hover {
  background-color: var(--primary-color-500);
  color: var(--white);
  cursor: pointer;
}

.c4p-card-view-list-item-popup {
  padding: 2px 1px 2px 1px;
  margin: 0px 2px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  border: solid;
  border-width: 1px;
  border-color: var(--card-shade-line-color);
  color: var(--form-font-color);
  background-color: var(--form-bg-color);
  text-align: center;
  font-size: 12px;
  height: 13px;
}

.c4p-card-view-list-item-popup:hover {
  background-color: var(--primary-color-500);
  color: var(--white);
  cursor: pointer;
}

.c4p-card-view-header {
  padding: 15px;
  justify-content: center !important;
  text-align: center;
}

.c4p-note-view-header {
  padding: 10px 0 10px 5px;
  align-items: center !important;
}

.c4p-card-view-notification {
  text-align: right;
}

.c4p-card-view-content {
  border: solid;
  border-width: 1px 0px 1px 0px;
  border-color: var(--card-shade-line-color);
  background-color: var(--card-shade-bg-color);
  color: var(--card-shade-font-color);
  padding: 5px;
  height: 152px;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.c4p-card-view-footer {
  padding: 10px 0px 0px 0px;
  justify-content: center !important;
  text-align: center;
  background-color: var(--card-shade-bg-color);
}

.c4p-card-notes-footer {
  padding: 5px 10px 0px 5px;
  background-color: var(--card-shade-bg-color);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c4p-card-view-img-circle {
  height: 96px;
  border-radius: 50%;
}

.c4p-avatar-small {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;
}

.c4p-avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: white;
}

.c4p-avatar-input {
  display: flex;
}

.c4p-avatar-input-edit {
  display: flex;
}

.c4p-avatar-input-edit:hover {
  cursor: pointer;
}

.c4p-collaboration-detail-card {
  border-radius: 4px !important;
  border-width: 1px;
  border-color: var(--card-shade-line-color) !important;
  background-color: white !important;
  padding: 5px 10px;
}

.c4p-dimmed {
  opacity: 0.6;
}

.c4p-dimmed .avatar-content {
  opacity: 0.5 !important;
}

.c4p-font-bold {
  font-weight: 600 !important;
}

.c4p-font-xlarge {
  font-family: var(--tuula-font-family);
  font-size: 18px;
  font-weight: 400;
}

.c4p-font-large {
  font-family: var(--tuula-font-family);
  font-size: 14px;
  font-weight: 400;
}

.c4p-font-medium {
  font-family: var(--tuula-font-family);
  font-size: 13px;
  font-weight: 400;
}

.c4p-font-normal {
  font-family: var(--tuula-font-family);
  font-size: 12px;
  font-weight: 400;
}

.c4p-font-small {
  font-family: var(--tuula-font-family);
  font-size: 11px;
  font-weight: 400;
}

.mat-mdc-list-base {
  padding-top: 8px;
  display: block;
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-option:not(.mat-mdc-list-item-disabled) {
  cursor: pointer;
  outline: none;
  margin: 4px 6px 6px 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  width: 290px;
  padding: 5px 0px;
  background-color: white;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-icon,
.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  box-sizing: content-box;
  border-radius: 50%;
  opacity: 0.5;
  padding: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-base .mat-mdc-list-option > * {
  margin: 1px;
  padding: 0;
  font-weight: normal;
  font-size: 11px !important;
  opacity: 1;
  color: var(--form-font-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-base .mat-mdc-list-option .mat-line:nth-child(n+2) {
  font-size: 12px !important;
  color: var(--primary-color-700) !important;
  font-weight: 400 !important;
}

.mat-mdc-dialog-container {
  color: var(--white);
  padding: 0px !important;
  margin: 0px !important;
  background-color: var(--master-bg-color) !important;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: var(--dark-border);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  max-width: 100%;
  overflow: visible !important;
}

.invoice-credit-replace-dialog-borderless .mat-mdc-dialog-container {
  border-style: none !important;
}

.c4p-popup {
  width: 100%;
}

.c4p-popup-title {
  background-color: var(--primary-color-500) !important;
  color: var(--white);
  font-family: var(--tuula-font-family);
  font-size: 14px;
  height: 35px;
  position: relative;
  padding-top: 20px;
  padding-left: 10px;
}

.c4p-form-input-listbox-bold {
  background-color: var(--master-bg-color) !important;
}

.c4p-popup-title-small {
  background-color: var(--primary-color-500) !important;
  color: var(--white);
  font-family: var(--tuula-font-family);
  font-size: 13px;
  height: 25px;
  position: relative;
  padding-top: 10px;
  padding-left: 10px;
}

.c4p-popup-content-top {
  display: flex;
  border-radius: 0px;
  border-width: 1px 0px 0px 0px !important;
  border: solid;
  box-shadow: none;
  border-color: var(--form-title-line-color);
  background-color: var(--form-bg-color) !important;
  color: var(--form-font-color) !important;
  font-family: var(--tuula-font-family);
  font-size: 12px;
  padding: 10px 30px 10px 20px;
}

.c4p-popup-content-down {
  display: flex;
  border-radius: 0px;
  border-width: 1px 0px 0px 0px !important;
  border: solid;
  box-shadow: none;
  border-color: var(--form-line-color);
  background-color: var(--form-shade-bg-color) !important;
  color: var(--form-font-color) !important;
  font-family: var(--tuula-font-family);
  font-size: 12px;
  padding: 10px 20px 10px 20px;
}

.c4p-popup-content-left-part {
  display: inline-block;
  width: 25px;
  vertical-align: top;
  padding-top: 3px;
}

.c4p-popup-content-right-part {
  display: inline-block;
  width: calc(100% - 25px);
  vertical-align: middle;
}

.maxh .c4p-popup-form, .maxh .c4p-popup-form-large, .maxh .c4p-popup-form-medium, .maxh .c4p-popup-form-small, .maxh .c4p-popup-form-share-and-sign {
  max-height: 92vh;
  min-width: 55vw;
}

.c4p-hidden-button {
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--primary-color-500);
  color: white;
  cursor: pointer;
  font-size: 12px;
  transition: width 0.5s;
}

.c4p-hidden-button:empty {
  width: 0%;
}

.c4p-button {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0px 8px 0px 8px;
  background-color: var(--primary-color-500);
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  font-size: 12px;
}

.c4p-button-ok {
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--primary-color-500);
  color: white;
  cursor: pointer;
  font-size: 12px;
  transition: width 0.5s;
  margin: 0px 2px 2px 2px;
  border-radius: 4px;
}

.c4p-button-ok:hover {
  background-color: var(--primary-color-400);
}

.c4p-button-ok:empty {
  width: 0%;
}

.sticky-btm-buttons {
  display: flex;
  position: sticky;
  bottom: 0px;
}

.c4p-button-cancel {
  text-align: center;
  width: 100%;
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--primary-color-800);
  cursor: pointer;
  font-size: 12px;
  transition: width 1s;
  margin: 0px 2px 2px 2px;
  border-radius: 4px;
  border: 1px solid var(--gray-color-200);
}

.c4p-button-cancel:hover {
  background-color: var(--gray-color-300);
}

.c4p-button-delete {
  text-align: center;
  text-shadow: 0px 0px 3px black;
  width: 100%;
  background-color: #d3c314;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  transition: width 1s;
  margin: 0px 2px 2px 2px;
  border-radius: 4px;
}

.c4p-button-delete:hover {
  background-color: var(--primary-color-400);
}

.c4p-button {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0px 8px 0px 8px;
  background-color: var(--primary-color-500);
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  font-size: 12px;
}

.c4p-button:hover {
  background-color: var(--primary-color-400);
}

.c4p-button-comment {
  margin: 5px 3px 5px 3px !important;
  font-size: 12px;
}

.c4p-button:hover:enabled {
  background-color: var(--primary-color-800);
}

.c4p-button-icon {
  color: #1db0e8;
}

.c4p-button[disabled] {
  opacity: 0.5;
  cursor: auto;
}

.c4p-tab {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px 1px 0px 0px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  background-color: var(--top-bg-color);
  border: solid;
  border-color: var(--primary-color-500);
  border-width: 0px 0px 4px 0px;
  border-radius: 0px;
  color: var(--primary-color-500);
  cursor: pointer;
  font-size: 13px;
}

.c4p-dimmed-tab {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px 1px 0px 0px;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--tuula-font-family);
  border: solid;
  border-width: 0px 0px 4px 0px;
  border-radius: 0px;
  border-color: var(--primary-color-500);
  outline: none;
  color: var(--top-font-color);
  cursor: pointer;
  font-size: 13px;
  opacity: 0.6;
}

.c4p-active-tab {
  opacity: 1;
}

.c4p-tab:hover,
.c4p-dimmed-tab:hover {
  opacity: 1;
}

.c4p-spinner-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.c4p-progress-center {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
}

.c4p-spinner-overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.roles-table {
  height: 100%;
  max-height: 250px !important;
  width: 100%;
}

.checkbox-cell {
  justify-content: center;
}

.mat-mdc-cell {
  color: var(--form-font-color);
  font-family: var(--tuula-font-family);
  font-size: 12px;
  font-weight: 400;
}

.permissions-panel .mat-expansion-panel-header {
  font-size: 12px !important;
}
.permissions-panel .mat-expansion-panel-header.mat-expanded {
  font-family: var(--tuula-font-family);
  font-size: 12px !important;
  font-weight: 400;
  background: var(--table-header-bg-color) !important;
}

.c4p-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.c4p-flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 60;
  flex-grow: 1;
}

.c4p-change-indicator {
  font-size: 16px;
  opacity: 0.7;
  margin-left: auto;
  color: var(--button-line-color);
  animation: pulse-icon 2s infinite;
}

.cdk-overlay-pane .report-modal .cancel-button-container {
  display: flex;
}

@media (max-width: 1800px) {
  .c4p-flex-row {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
@keyframes pulse-icon {
  0% {
    transform: scale(0.99);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.99);
  }
}
.c4p-link {
  color: #00afea;
  text-decoration: none;
}

.ql-toolbar.ql-snow {
  margin-top: 10px;
  border: 1px solid #eaeaea !important;
}

.ql-editor p {
  word-break: normal;
}

.ql-snow.ql-toolbar button {
  height: 20px !important;
  width: 24px !important;
  opacity: 0.8;
}

.ql-snow .ql-picker {
  height: 20px;
}

.ql-container.ql-snow {
  border: 1px solid #eaeaea !important;
  min-height: 100px;
  height: auto;
}

.c4p-small-label {
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 10px;
  opacity: 0.6;
  margin: 16px 0px 0px 16px;
  background-color: var(--form-bg-color);
}

.c4p-small-title-label {
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  opacity: 0.6;
  margin: 16px 0px 0px 16px;
  color: black;
}

.c4p-radio-button {
  margin: 5px;
}

.c4p-radio-button-question-set {
  margin-left: 10px;
  margin-bottom: 0;
  margin-top: 0;
}

.c4p-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.phone-column {
  flex-basis: 60;
  padding-right: 3px;
}

.email-column {
  flex-basis: 40;
  padding-left: 3px;
}

.c4p-empty-table-row {
  justify-content: center;
  font-style: italic;
  font-size: 12px;
  height: 45px;
  color: var(--form-font-color);
}

.c4p-tooltip-preline {
  white-space: pre-line !important;
}

.c4p-tooltip {
  position: absolute;
  font-size: 12px;
  color: var(--form-font-color);
  width: auto;
  padding: 8px;
  border-radius: 4px;
  /* Optional: Adjust shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: var(--card-line-color);
}

.c4p-tooltip-bg-default {
  background: var(--master-bg-color);
  color: var(--form-font-color);
}

.c4p-tooltip-bg-dark {
  background: #101828;
  color: #E3E4E5;
}

.c4p-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
}

.c4p-tooltip-top::after {
  border-width: 8px 8px 0 8px;
  border-color: #333 transparent transparent transparent;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.c4p-tooltip-bottom::after {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #333 transparent;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.c4p-tooltip-left::after {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #333;
  top: 50%;
  right: -8px;
  transform: translateY(-30%);
}

.c4p-tooltip-right::after {
  border-width: 8px 8px 8px 0;
  border-color: transparent #333 transparent transparent;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
}

.c4p-tooltip-show {
  opacity: 1;
}

.c4p-widget-Small {
  width: 320px;
  height: 320px;
}

.c4p-widget-Medium {
  width: 652px;
  height: 320px;
}

.c4p-widget-Large {
  width: 652px;
  height: 652px;
}

.c4p-widget-Extra {
  width: 984px;
  height: 652px;
}

.c4p-widget-Fit {
  width: 100% !important;
  height: 100%;
  min-width: 120px;
}

.c4p-widget-Full {
  width: unset;
  height: unset;
  min-width: 320px;
  min-height: 320px;
}

.c4p-widget-title {
  height: 12px;
  color: var(--primary-color-700);
  display: flex;
  position: absolute;
  justify-content: flex-end;
  border-width: 0px;
  border-color: var(--form-title-line-color);
  border-bottom-width: 1px;
  font-size: 11px;
  align-items: center;
  width: 100%;
  z-index: 100;
}

.c4p-widget-icons {
  font-size: 11px;
  width: 16px !important;
  height: unset !important;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.c4p-widget-content {
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--form-title-bg-color);
  height: inherit;
}

.c4p-widget-icons:hover {
  opacity: 1;
  color: var(--primary-color-500);
}

.c4p-widget {
  display: flex;
}

.c4p-widget-view {
  justify-content: center !important;
  text-align: center;
  min-width: 110px;
  width: 100%;
  padding: 8px;
}

.c4p-widget-view-no-padding {
  justify-content: center !important;
  text-align: left;
  min-width: 110px;
  width: 100%;
}

.c4p-widget-horizontal-line {
  border: solid;
  border-width: 1px 0px 0px 0px;
  border-color: var(--form-line-color);
  height: 0px;
  margin: 6px -8px;
}

.c4p-view-label {
  margin: 6px 0px 0px 0px;
  color: var(--form-font-color);
}

.c4p-view-value {
  margin: 6px 0px 0px 0px;
  font-size: 13px;
}

.panel-icon {
  font-size: 16px;
  padding-right: 5px;
  opacity: 0.4;
}

.fc {
  display: flex;
  flex-direction: column;
  font-size: 11px !important;
}

.fc .fc-toolbar-title {
  font-size: 13px !important;
  margin: 7px !important;
  text-align: center;
}

.fc-toolbar-chunk {
  display: flex !important;
}

.fc .fc-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: var(--primary-color-500) !important;
  border: 1px solid var(--primary-color-800) !important;
  border-radius: 0.25em;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 400;
  line-height: unset !important;
  padding: 3px !important;
  text-align: center;
  user-select: none;
  vertical-align: middle;
}

.fc .fc-button-primary:disabled {
  background-color: var(--primary-color-500) !important;
  border-color: var(--primary-color-800) !important;
  color: var(--white) !important;
}

.badge {
  display: inline-block;
  padding: 8px 10px;
  color: white;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc .fc-button-active {
  background-color: var(--primary-color-800) !important;
  box-shadow: unset !important;
}

.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
  background-color: white !important;
}

.fc .fc-button .fc-icon {
  font-size: 13px !important;
  vertical-align: middle;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 4px !important;
}

.c4p-form-goals-container {
  overflow-y: scroll;
  height: auto;
  min-height: 30hv;
  padding: 10px;
  background-color: var(--form-bg-color);
}

.c4p-form-goals-popup-container {
  overflow-y: scroll;
  padding: 10px;
  background-color: var(--form-bg-color);
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.c4p-object-list-multiple {
  font-family: var(--tuula-font-family);
  font-size: 12px;
  color: var(--winter-wizard-color-700);
  background-color: var(--winter-wizard-color-50);
  border: 1px solid var(--winter-wizard-color-200);
  border-radius: 16px;
  display: block;
  mix-blend-mode: multiply;
  padding: 0 6px;
}

.c4p-object-list-menu {
  background-color: var(--primary-color-500) !important;
  border-radius: 4px !important;
}

.c4p-object-list-menu-item {
  background-color: var(--white) !important;
  border-width: 0 0 1px 0;
  border-color: var(--primary-color-500);
}
.c4p-object-list-menu-item span {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.mat-column-icomSelectExternalProduct {
  flex: 0 0 15%;
}

.mat-column-icomSelectNewExternalProduct {
  flex: 0 0 15%;
}

.mat-column-icomSelectChangedExternalProduct {
  flex: 0 0 15%;
}

.mat-column-icomMissingData {
  flex: 0 0 20%;
}

.c4p-form-carefile-icom-error-flex {
  display: flex;
}

.c4p-form-carefile-icom-panel {
  max-width: 90vh;
}

@media screen and (max-width: 800px) {
  .c4p-tablet-screen {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .c4p-mobile-screen {
    display: none;
  }
}
.c4p-object-list-panel-header {
  color: var(--form-font-color);
  opacity: 0.6;
  font-family: var(--tuula-font-family);
  font-size: 10px;
}

.ngx-material-timepicker-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  border: none;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.3s;
  cursor: pointer;
  fill: var(--form-font-color);
  opacity: 0.6;
  width: 21px;
  height: 21px;
}

.c4p-row-title {
  display: inline-block;
  position: absolute;
  color: white;
  font-size: 10px;
  transform: rotate(-90deg);
  padding: 4px;
  margin-left: -24px;
  white-space: nowrap;
  width: 56px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  word-wrap: normal;
}

.c4p-row-title-containter {
  min-height: 75px;
  margin-right: -16px;
  vertical-align: middle;
  min-width: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.45);
  background-color: #a0a0a0;
  border-radius: 4px;
  position: relative;
  left: -18px;
  border-radius: 4px 0px 0px 4px;
}

.c4p-average-score {
  background-color: var(--primary-color-500);
  color: var(--white);
  font-family: var(--tuula-font-family);
  text-align: center;
  font-size: 24px;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-mdc-list-base .mat-mdc-list-option {
  width: unset !important;
  padding: 6px 0px !important;
}

.spinner-item {
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.x-large {
  width: 67vw;
}

.x-large-overflow {
  margin-top: 20px;
  width: 67vw;
  overflow: scroll;
  height: 50px !important;
}

.menuBtns .mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
}

.menuBtns .mat-mdc-menu-content .c4p-button {
  margin: 0;
}

app-form-input-dropdown.internalProduct .c4p-form-input {
  align-items: flex-start !important;
}

.google-visualization-tooltip-item-list li:nth-child(1) {
  margin-bottom: -10px !important;
}

.google-visualization-tooltip-square {
  background-color: #ffffff !important;
}

.google-visualization-tooltip-square + span {
  color: #ffffff !important;
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: none;
  }
}
@media only screen and (max-width: 1000px) {
  .c4p-popup-form, .c4p-popup-form-large, .c4p-popup-form-medium, .c4p-popup-form-small, .c4p-popup-form-share-and-sign {
    max-height: 80vh;
  }
  .c4p-form-questionset {
    display: contents;
  }
  .c4p-form-questionset .c4p-top-icons {
    display: none;
  }
  .c4p-form-questions-title {
    display: contents;
  }
  .c4p-content {
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
  }
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: none;
  }
  .c4p-table-view-wrap {
    min-width: 300px;
  }
  .multipleBtn .c4p-table-view-wrap .c4p-table-title-bar {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    min-height: 95px;
  }
  .minh-130 .c4p-table-view-wrap .c4p-table-title-bar {
    min-height: 130px;
  }
  .multipleBtn .c4p-button {
    margin-bottom: 5px;
  }
  .multipleBtn .c4p-align-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }
  .multipleBtns .c4p-flex-title {
    flex-direction: column !important;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    height: 60px;
  }
  .c4p-grid {
    justify-content: center;
  }
  .w-large {
    width: 400px;
  }
  .cdk-overlay-pane .report-modal {
    max-height: 85vh;
    overflow: auto;
  }
  .cdk-overlay-pane .report-modal .cancel-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .c4p-form-question-answers-container {
    max-height: unset;
    overflow: unset;
  }
  .c4p-form-row-right {
    justify-content: start;
  }
}
.refferal-doc .c4p-content {
  padding: 0 !important;
}

[data-tippy-root] .tippy-content {
  max-height: 150px;
  overflow-y: auto;
}

.invalid-title {
  color: #f70000;
}

quill-editor {
  word-break: keep-all;
  width: -webkit-fill-available;
}

.disabled-inline-action {
  opacity: 0.5;
  pointer-events: none;
}

@media only screen and (min-height: 960px) {
  .c4p-form-question-answers-container {
    max-height: calc(100vh - 230px);
  }
  .c4p-form-questionset mat-table {
    max-height: unset;
  }
}
.ag-theme-alpine {
  --ag-odd-row-background-color: rgba(0, 81, 255, 0.07);
}

.ag-theme-balham {
  --ag-odd-row-background-color: rgb(0, 81, 255, 0.07);
}

.ag-theme-material {
  --ag-odd-row-background-color: rgb(0, 81, 255, 0.07);
}

.cellCenter .ag-cell-wrapper {
  justify-content: center;
}

.mat-mdc-autocomplete-panel {
  background-color: var(--form-bg-color) !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.update-medication-button {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

mat-button-toggle.mat-button-toggle-checked span {
  margin-bottom: 60px;
  border-bottom: var(--primary-color-500) solid !important;
}

.empty-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  margin-bottom: 160px;
}

.empty-table-on {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*carefile export*/
app-panel.bg-clr-white > div.c4p-popup-form, app-panel.bg-clr-white > div.c4p-popup-form-large, app-panel.bg-clr-white > div.c4p-popup-form-medium, app-panel.bg-clr-white > div.c4p-popup-form-small, app-panel.bg-clr-white > div.c4p-popup-form-share-and-sign {
  background-color: #fff;
}

app-panel-form-row.border-none > div.c4p-form-row {
  border: none;
}

app-panel-form-row.bottom {
  position: relative;
}

app-panel-form-row.bottom > div:before {
  content: "";
  position: absolute;
  left: 30px;
  bottom: 0;
  height: 15px;
  width: 90%;
  border-bottom: 1.5px dashed #a0a0a0;
}

.newdesign-label {
  margin-left: 30px;
  margin-top: 20px;
  background-color: #fff;
}

/*carefile export*/
.wide-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
}

.toast-success {
  background-color: var(--success-color-100) !important;
  color: var(--primary-color-700) !important;
  background-image: url(/assets/svg/success.svg) !important;
  background-size: 35px !important;
}

.toast-error {
  background-color: var(--error-color-100) !important;
  color: var(--primary-color-700) !important;
  background-image: url(/assets/svg/error.svg) !important;
  background-size: 35px !important;
}

.toast-warning {
  background-color: var(--warning-color-100) !important;
  color: var(--primary-color-700) !important;
  background-image: url(/assets/svg/warning.svg) !important;
  background-size: 35px !important;
}

.toast-info {
  background-color: var(--winter-wizard-color-100) !important;
  color: var(--primary-color-700) !important;
  background-image: url(/assets/svg/info.svg) !important;
  background-size: 35px !important;
}

app-assessment-registration > app-page > app-page-content > div.c4p-content > app-panel > div.c4p-form {
  box-shadow: none;
}

app-budget-utilization > app-page > app-page-content > div.c4p-content > app-panel > div.c4p-form {
  box-shadow: none;
}

app-configuration-main > app-page > app-page-content > div.c4p-content {
  height: 100%;
}

.employee-create-container .c4p-form {
  background-color: white;
  box-shadow: none;
}

.tuula-section-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray-color-900);
  margin-top: 20px;
  margin-bottom: 28px;
  margin-left: 1px;
}